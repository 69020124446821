const firebaseConfig = {
    apiKey: "AIzaSyDGQCtBZdEgRHYilIjbGacgoMQz_JLgbjo",
    authDomain: "icsa-planillas-9fc6e.firebaseapp.com",
    databaseURL: "https://icsa-planillas-9fc6e.firebaseio.com",
    projectId: "icsa-planillas-9fc6e",
    storageBucket: "icsa-planillas-9fc6e.appspot.com",
    messagingSenderId: "183755188628",
    appId: "1:183755188628:web:86ca273451adb300d7c781",
    measurementId: "G-4QSY8E753X"
  };

  export default firebaseConfig;