import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import servidor from '../../util/servidor';
import { Button } from '@material-ui/core';
import { Dialog } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        height: '-webkit-fill-available',
        marginBottom: '4.5rem'
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    fecha: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        borderColor: 'black',
        borderWidth: 'thin',
        borderStyle: 'solid',
        borderRadius: 20
    },
    fechaInicio: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center'
    },
    fechaFinal: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center'
    },
    inputDia: {
        width: '10rem'
    },
    lista: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: '#fafcfc',
        flexGrow: 1
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    },
    totales: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        backgroundColor: 'aliceBlue'
    },
    datos: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    },
    dato: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    nombre: {
        fontSize: '1.2rem'
    },
    footer: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#010326',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '100%'
    }
}));

export default function PlanillaConstruccionMovil(props) {
    const classes = useStyles();
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openDeny, setOpenDeny] = useState(false);
    const [diaInicio, setDiaInicio] = useState('');
    const [diaFinal, setDiaFinal] = useState('');
    const [diaInicioFecha, setDiaInicioFecha] = useState('');
    const [diaFinalFecha, setDiaFinalFecha] = useState('');
    const [mesInicio, setMesInicio] = useState('');
    const [mesFinal, setMesFinal] = useState('');
    const [anoInicio, setAnoInicio] = useState(new Date().getFullYear());
    const [anoFinal, setAnoFinal] = useState(new Date().getFullYear());
    const [devengadoTotal, setDevengadoTotal] = useState(0);
    const [cajaTotal, setCajaTotal] = useState(0);
    const [netoTotal, setNetoTotal] = useState(0);

    const meses = useMemo(() => ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "setiembre", "octubre", "noviembre", "diciembre"], []);

    const calcularFechas = useCallback(
        () => {
            let hoy = new Date();
            let dia = hoy.getDay();
            let unDia = 24 * 60 * 60 * 1000;
            let fechaInicioSeg = '';
            let fechaFinalSeg = '';
            if (dia === 1) {
                fechaInicioSeg = hoy.getTime() - 3 * unDia;
                fechaFinalSeg = hoy.getTime() + 3 * unDia;
            } else if (dia === 2) {
                fechaInicioSeg = hoy.getTime() - 4 * unDia;
                fechaFinalSeg = hoy.getTime() + 2 * unDia;
            } else if (dia === 3) {
                fechaInicioSeg = hoy.getTime() - 5 * unDia;
                fechaFinalSeg = hoy.getTime() + 1 * unDia;
            } else if (dia === 4) {
                fechaInicioSeg = hoy.getTime() - 6 * unDia;
                fechaFinalSeg = hoy.getTime() + 0 * unDia;
            } else if (dia === 5) {
                fechaInicioSeg = hoy.getTime() - 7 * unDia;
                fechaFinalSeg = hoy.getTime() - 1 * unDia;
            } else if (dia === 6) {
                fechaInicioSeg = hoy.getTime() - 8 * unDia;
                fechaFinalSeg = hoy.getTime() - 2 * unDia;
            } else if (dia === 7) {
                fechaInicioSeg = hoy.getTime() - 9 * unDia;
                fechaFinalSeg = hoy.getTime() - 3 * unDia;
            }

            let fechaInicio = new Date(fechaInicioSeg);
            let fechaFinal = new Date(fechaFinalSeg);

            setDiaInicioFecha(fechaInicio);
            setDiaFinalFecha(fechaFinal);
            setDiaInicio(fechaInicio.getDate());
            setDiaFinal(fechaFinal.getDate());
            setMesInicio(meses[fechaInicio.getMonth()]);
            setMesFinal(meses[fechaFinal.getMonth()]);
            setAnoInicio(fechaInicio.getFullYear());
            setAnoFinal(fechaFinal.getFullYear());
        },[meses]
    );
    const [salario, setSalario] = useState({});
    const [empleadosConstruccion, setEmpleadosConstruccion] = useState([]);
    //codigo sacado de los faqs de React
    useEffect(() => {
        let ignore = false;

        async function getData() {
            if (!ignore) {
                let response = await servidor.empleadosConstruccionActivos(props.token);
                setEmpleadosConstruccion(response);
                let devengadoTotal = 0;
                let cajaTotal = 0;
                let netoTotal = 0;
                response.forEach((trabajador, index) => {
                    let devengado = calcularSalarioDevengado(trabajador.HorasTrabajo, trabajador.SalarioPorHora);
                    let caja = calcularCaja(devengado);
                    let neto = calcularSalarioNeto(devengado, caja);
                    devengadoTotal = devengadoTotal + devengado;
                    cajaTotal = cajaTotal + caja;
                    netoTotal = netoTotal + neto;
                    setSalario(salario => ({ ...salario, [`trabajador-${index}`]: { devengado: devengado, caja: caja, neto: neto } }))
                });
                setDevengadoTotal(devengadoTotal);
                setCajaTotal(cajaTotal);
                setNetoTotal(netoTotal);
                calcularFechas();
            }
        }
        getData();
        return () => { ignore = true; }
    }, [props.token, calcularFechas]);

    function handleCloseConfirm() {
        setOpenConfirm(false);
        window.open(`/`, '_self');
    }

    function handleClose() {
        setOpenDeny(false);
    }

    function calcularSalarioDevengado(horas, salarioPorHora) {
        let ordinarias = 0;
        let extras = 0;
        let dobles = 0;
        let salarioDevengado;

        if (horas > 60) {
            dobles = horas - 60;
            extras = 12;
            ordinarias = 48;
        } else if (horas <= 60 && horas > 48) {
            extras = horas - 48;
            ordinarias = 48;
        } else if (horas <= 48) {
            ordinarias = horas;
        }

        salarioDevengado = salarioPorHora * 2 * dobles + salarioPorHora * 1.5 * extras + salarioPorHora * ordinarias;
        return salarioDevengado;
    }

    function calcularCaja(devengado) {
        let caja = devengado * 0.105;
        return caja;
    }

    function calcularSalarioNeto(devengado, caja) {
        var neto = devengado - caja;
        return neto;
    }

    function cambioFechaInicio(date) {
        setDiaInicio(date.getDate());
        setMesInicio(meses[date.getMonth()]);
        setAnoInicio(date.getFullYear());
        setDiaInicioFecha(date);
    }

    function cambioFechaFinal(date) {
        setDiaFinal(date.getDate());
        setMesFinal(meses[date.getMonth()]);
        setAnoFinal(date.getFullYear());
        setDiaFinalFecha(date);
    }

    async function guardarPlanilla() {
        let params = {
            planilla: {
                diaInicio: diaInicio,
                diaFinal: diaFinal,
                mesInicio: mesInicio,
                mesFinal: mesFinal,
                anoInicio: anoInicio,
                anoFinal: anoFinal
            },
            constructores: empleadosConstruccion.map(constructor => {
                return {
                    constructor_id: constructor.Id,
                    nombre: constructor.Nombre,
                    horasTrabajadas: constructor.HorasTrabajo,
                    salarioPorHora: constructor.SalarioPorHora,
                    proyecto: constructor.Proyecto,
                    puesto: constructor.Puesto
                }
            })
        };

        servidor.guardarPlanillaConstruccion(props.token, params)
            .then((response) => {
                if (response.status === 200) {
                    setOpenConfirm(true);
                    return;
                } else {
                    setOpenDeny(true);
                    return;
                }
            });
    }

    return (
        <Container className={classes.root}>
            <List subheader={<ListSubheader style={{ textAlign: 'left' }}>Planilla Construcción</ListSubheader>} className={classes.lista}>
                <Container className={classes.fecha}>
                    <Container className={classes.fechaInicio}>
                        <Typography>Desde</Typography>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                className={classes.inputDia}
                                value={diaInicioFecha}
                                margin='normal'
                                format='dd/MM/yyyy'
                                onChange={cambioFechaInicio}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Container>
                    <Container className={classes.fechaFinal}>
                        <Typography>Hasta</Typography>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                className={classes.inputDia}
                                value={diaFinalFecha}
                                margin="normal"
                                format='dd/MM/yyyy'
                                onChange={cambioFechaFinal}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Container>
                </Container>
                {empleadosConstruccion.length > 0 ? empleadosConstruccion.map((constructor, index) => {
                    return (
                        <ListItem key={constructor.Id} className={classes.item} divider={true}>
                            <ListItemText classes={{ primary: classes.nombre }} primary={constructor.Nombre} />
                            <Container className={classes.datos}>
                                <Container className={classes.dato}>
                                    <Typography>Devengado: </Typography>
                                    <Typography>{salario[`trabajador-${index}`] ? (salario[`trabajador-${index}`].devengado).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}</Typography>
                                </Container>
                                <Container className={classes.dato}>
                                    <Typography>C.C.S.S.: </Typography>
                                    <Typography>{salario[`trabajador-${index}`] ? (salario[`trabajador-${index}`].caja).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}</Typography>
                                </Container>
                                <Container className={classes.dato}>
                                    <Typography>Salario Neto: </Typography>
                                    <Typography>{salario[`trabajador-${index}`] ? (salario[`trabajador-${index}`].neto).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}</Typography>
                                </Container>
                            </Container>
                        </ListItem>
                    );
                })
                    : <CircularProgress color="primary" />
                }
                <ListItem className={classes.totales} >
                    <ListItemText classes={{ primary: classes.nombre }} primary='Total' />
                    <Container className={classes.datos}>
                        <Container className={classes.dato}>
                            <Typography>Devengado Total: </Typography>
                            <Typography>{(devengadoTotal).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</Typography>
                        </Container>
                        <Container className={classes.dato}>
                            <Typography>C.C.S.S. Total: </Typography>
                            <Typography>{(cajaTotal).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</Typography>
                        </Container>
                        <Container className={classes.dato}>
                            <Typography>Salario Neto Total: </Typography>
                            <Typography>{(netoTotal).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</Typography>
                        </Container>
                    </Container>
                </ListItem>
            </List>
            <BottomNavigation className={classes.footer}>
                <BottomNavigationAction icon={<ArrowBackIcon color='primary' />} onClick={() => window.open('/registroHorasMovil', '_self')} />
                <BottomNavigationAction icon={<SaveIcon color='primary' />} onClick={guardarPlanilla} />
            </BottomNavigation>

            <Dialog onClose={handleCloseConfirm} open={openConfirm}>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-confirm">
                        Se registró la planilla correctamente.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirm} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog onClose={handleClose} open={openDeny}>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-deny">
                        No se pudo registrar la planilla. Compruebe su conección a internet.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </Container >
    );
}