import React from 'react';
import ReactDOM from 'react-dom';
import './App.css';
//import withFirebaseAuth from 'react-with-firebase-auth'
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
//import 'firebase/messaging';
import firebaseConfig from './util/firebaseConfig';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Constructores from "./Modulos/Constructores/Constructores";
import ConstructoresMovil from "./Modulos/Movil/ConstructoresMovil";
import VerConstructorMovil from "./Modulos/Movil/VerConstructorMovil";
import ModificarConstructorMovil from "./Modulos/Movil/ModificarConstructorMovil";
import AgregarConstructorMovil from "./Modulos/Movil/AgregarConstructorMovil";
import RegistroHorasMovil from "./Modulos/Movil/RegistroHorasMovil";
import PlanillaConstruccionMovil from "./Modulos/Movil/PlanillaConstruccionMovil";
import PlanillaConstruccion from "./Modulos/Constructores/PlanillaConstruccion/PlanillaConstruccion";
import Header from "./Componentes/Header/Header";
import HeaderMovil from "./Componentes/Header/HeaderMovil";
import SignIn from './Componentes/SignIn/SignIn';
//import credencialesServidor from './util/credencialesServidor';
import RegistroHoras from './Modulos/Constructores/RegistroHoras/RegistroHoras';
import RevisionHoras from './Modulos/Constructores/RevisionHoras/RevisionHoras';
import AgregarConstructor from './Modulos/Constructores/AgregarConstructor/AgregarConstructor';
import ModificarConstructor from './Modulos/Constructores/ModificarConstructor/ModificarConstructor';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core';
import Administrativos from './Modulos/Administrativos/Administrativos';
import AgregarAdministrativo from './Modulos/Administrativos/AgregarAdministrativo/AgregarAdministrativo';
import ModificarAdministrativo from './Modulos/Administrativos/ModificarAdministrativo/ModificarAdministrativo';
import { PlanillaAdministrativa } from "./Modulos/Administrativos/PlanillaAdministrativa/PlanillaAdministrativa";
import PlanillasAnteriores from "./Modulos/PlanillasAnteriores/PlanillasAnteriores";
import { PlanillaConstruccionAnterior } from "./Modulos/PlanillasAnteriores/PlanillaConstruccionAnterior";
import { PlanillaConstruccionAnteriorCaja } from "./Modulos/PlanillasAnteriores/PlanillaConstruccionAnteriorCaja";
import RegistroRevisionHoras from "./Modulos/PlanillasAnteriores/RegistroRevisionHoras";
import CircularProgress from '@material-ui/core/CircularProgress';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#fa6320' },
    secondary: { main: "#010326" },
  },
  status: {
    danger: "#282c34",
  }
});

firebase.initializeApp(firebaseConfig);

//const firebaseAppAuth = firebaseApp.auth();
//const providers = {
//  googleProvider: new firebase.auth.GoogleAuthProvider(),
//};

//FIREBASE MESSAGING ROMPE EL APP EN IPHONE
/*//Notificaciones PUSH
const messaging = firebase.messaging();
// Add the public key generated from the console here.
messaging.usePublicVapidKey("BDkgeY-7dwAC9amhz-0DenOhq_qWHlV32sBO4tCbtly_atu4jAmC-cHWdaPk3mECwT0hLVchyKt7DhB_Dmg_0Bg");
// Get Instance ID token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
messaging.getToken()
  .then(token => console.log(token));
messaging.onTokenRefresh(() => {
  messaging.getToken()
    .then(token => console.log(token));
});
messaging.onMessage((payload) => {
  console.log('Message received. ', payload);
  // ...
});*/


// Get a reference to the database service
//var database = firebase.firestore();

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      credenciales: {},
      mobile: false,
      signedIn: false,
      user: null,
      loading: false
    };

    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  signIn() {
    if (!this.state.signedIn) {
      this.setState({ signedIn: true });
    }
  }

  signOut() {
    firebase.auth.signOut();
    this.setState({ signedIn: false });
  }

  componentDidMount() {
    if (window.screen.width <= 720) {
      this.setState({ mobile: true });
    }
    ReactDOM.findDOMNode(this).addEventListener('sign-in', this.signIn());
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.signedIn !== this.state.signedIn) {
      firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          console.log('User is signed in.');
          this.setState({ user: user });
        } else {
          console.log('No user is signed in.');
          this.setState({ user: user });
        }
      }.bind(this));
    }
    if (this.state.user && prevState.user === null) {
      this.setState({ loading: false });
    }
    /*if (this.props.user === null && this.state.user !== 'SignIn') {
      this.setState({ user: 'SignIn' });
    }
    if (prevProps.user !== this.props.user && this.props.user !== null) {
      console.log(this.props.user);
      let credenciales = await credencialesServidor.credencialesGuardadas(this.props.user, database);
      this.setState({
        credenciales: credenciales,
        user: true
      });
    }
    if (prevState.credenciales !== this.state.credenciales) {
      if (this.props.user.email in this.state.credenciales) {
        if (Date.now() - this.state.credenciales[this.props.user.email].exp < 3500000) {
          console.log('TOKEN: vigente');
        } else {
          console.log('TOKEN: viejo');
          let credenciales = await credencialesServidor.refreshToken(this.state.credenciales[this.props.user.email].refresh_token, this.props.user, database);
          this.setState({ credenciales: credenciales });
        }
      } else {
        if (window.location.search === '') {
          credencialesServidor.pedirCodigo();
        } else {
          try {
            let credenciales = await credencialesServidor.crearCredenciales(this.props.user, database);
            this.setState({ credenciales: credenciales });
            window.open('/', '_self');
          } catch (e) { console.log(e) }
        }
      }
    }*/
  }

  render() {
    if (!this.state.user && this.state.signedIn) {
      return (
        <div className="Load" >
          <CircularProgress color="secondary" />
        </div>
      );
    }

    if (!this.state.user && !this.state.signedIn) {
      return (
        <ThemeProvider theme={theme}>
          <div className="App">
            <SignIn />
          </div>
        </ThemeProvider>);
    }

    if (this.state.user) {
      if (!this.state.mobile) {
        return (
          <ThemeProvider theme={theme}>
            <div className="App">
              <Router>
                <Header signOut={this.signOut} />
                <Switch>
                  <Route path="/planillasAnteriores" render={() => <PlanillasAnteriores user={this.state.user} token={this.state.user.email ? this.state.user.email.id_token : ''} />} />
                  <Route path="/planillaAdministrativa" render={() => <PlanillaAdministrativa user={this.state.user} token={this.state.user.email ? this.state.user.email.id_token : ''} />} />
                  <Route path="/modificarAdministrativo/:id" render={props => <ModificarAdministrativo id={props.match.params.id} user={this.state.user} token={this.state.user.email ? this.state.user.email.id_token : ''} />} />
                  <Route path="/agregarAdministrativo" render={() => <AgregarAdministrativo user={this.state.user} token={this.state.user.email ? this.state.user.email.id_token : ''} />} />
                  <Route path="/administrativos" render={() => <Administrativos user={this.state.user} token={this.state.user.email ? this.state.user.email.id_token : ''} />} />
                  <Route path="/modificarConstructor/:id" render={props => <ModificarConstructor id={props.match.params.id} user={this.state.user} token={this.state.user.email ? this.state.user.email.id_token : ''} />} />
                  <Route path="/agregarConstructor" render={() => <AgregarConstructor user={this.state.user} token={this.state.user.email ? this.state.user.email.id_token : ''} />} />
                  <Route path="/verRegistroRevisionHoras/:id" render={props => <RegistroRevisionHoras id={props.match.params.id} user={this.state.user} token={this.state.user.email ? this.state.user.email.id_token : ''} />} />
                  <Route path="/verPlanillaConstruccion/:id/:di/:mi/:ai/:df/:mf/:af" render={props => <PlanillaConstruccionAnterior id={props.match.params.id} di={props.match.params.di} mi={props.match.params.mi} ai={props.match.params.ai} df={props.match.params.df} mf={props.match.params.mf} af={props.match.params.af} user={this.state.user} token={this.state.user.email ? this.state.user.email.id_token : ''} />} />
                  <Route path="/verPlanillaConstruccionCaja/:id/:di/:mi/:ai/:df/:mf/:af" render={props => <PlanillaConstruccionAnteriorCaja id={props.match.params.id} di={props.match.params.di} mi={props.match.params.mi} ai={props.match.params.ai} df={props.match.params.df} mf={props.match.params.mf} af={props.match.params.af} user={this.state.user} token={this.state.user.email ? this.state.user.email.id_token : ''} />} />
                  <Route path="/planillaConstruccion" render={() => <PlanillaConstruccion user={this.state.user} token={this.state.user.email ? this.state.user.email.id_token : ''} />} />
                  <Route path="/revisionHoras" render={() => <RevisionHoras user={this.state.user} token={this.state.user.email ? this.state.user.email.id_token : ''} />} />
                  <Route path="/registroHoras" render={() => <RegistroHoras user={this.state.user} token={this.state.user.email ? this.state.user.email.id_token : ''} />} />
                  <Route path="/" render={() => <Constructores user={this.state.user} token={this.state.user.email ? this.state.user.email.id_token : ''} />} />
                </Switch>
              </Router>
            </div>
          </ThemeProvider>
        );
      } else {
        return (
          <ThemeProvider theme={theme}>
            <div className="App">
              <Router>
                <HeaderMovil />
                <Switch>
                  <Route path="/planillaConstruccionMovil" render={props => <PlanillaConstruccionMovil id={props.match.params.id} user={this.state.user} token={this.state.user.email ? this.state.user.email.id_token : ''} />} />
                  <Route path="/registroHorasMovil" render={props => <RegistroHorasMovil id={props.match.params.id} user={this.state.user} token={this.state.user.email ? this.state.user.email.id_token : ''} />} />
                  <Route path="/modificarConstructorMovil/:id" render={props => <ModificarConstructorMovil id={props.match.params.id} user={this.state.user} token={this.state.user.email ? this.state.user.email.id_token : ''} />} />
                  <Route path="/verConstructorMovil/:id" render={props => <VerConstructorMovil id={props.match.params.id} user={this.state.user} token={this.state.user.email ? this.state.user.email.id_token : ''} />} />
                  <Route path="/agregarConstructorMovil" render={props => <AgregarConstructorMovil id={props.match.params.id} user={this.state.user} token={this.state.user.email ? this.state.user.email.id_token : ''} />} />
                  <Route path="/" render={() => <ConstructoresMovil user={this.state.user} token={this.state.user.email ? this.state.user.email.id_token : ''} />} />
                </Switch>
              </Router>
            </div>
          </ThemeProvider>
        );
      }
    }
  }
}