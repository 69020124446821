import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Switch from '@material-ui/core/Switch';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import CircularProgress from '@material-ui/core/CircularProgress';
import servidor from '../../util/servidor';

const useStyles = makeStyles((theme) => ({
    constructoresMovil: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        height: '-webkit-fill-available',
        marginBottom: '4.5rem'
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    lista: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: '#fafcfc',
        flexGrow: 1
    },
    footer: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#010326',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '100%'
    }
}));

export default function ConstructoresMovil(props) {
    const classes = useStyles();
    const [empleadosConstruccion, setEmpleadosConstruccion] = useState([]);
    //codigo sacado de los faqs de React
    useEffect(() => {
        let ignore = false;

        async function getempleadosConstruccion() {
            if (!ignore) {
                let response = await servidor.empleadosConstruccion(props.token);
                await response.sort((a, b) => {
                    return b.Activo - a.Activo;
                });
                setEmpleadosConstruccion(response);
            }
        }
        getempleadosConstruccion();
        return () => { ignore = true; }
    }, [props.token]);

    function cambiarActividad(index) {
        let activo = (empleadosConstruccion[index].Activo === 1 ? 0 : 1);
        let id = empleadosConstruccion[index].Id;
        let params = {
            activo: activo,
            id: id
        };
        let nuevoEmpleadosConstruccion = [...empleadosConstruccion];
        nuevoEmpleadosConstruccion[index].Activo = activo;
        nuevoEmpleadosConstruccion.sort((a, b) => {
            return b.Activo - a.Activo;
        });
        servidor.cambiarActividad(props.token, params);
        setEmpleadosConstruccion(nuevoEmpleadosConstruccion);
    }

    return (
        <Container className={classes.constructoresMovil}>
            <List subheader={<ListSubheader style={{ textAlign: 'left' }}>Constructores</ListSubheader>} className={classes.lista}>
                {empleadosConstruccion.length > 0 ? empleadosConstruccion.map((constructor, index) => {
                    return (
                        <ListItem key={constructor.Id} onClick={() => window.open(`/verConstructorMovil/${constructor.Id}`, '_self')}>
                            <ListItemText id="switch-list-label-constructor" primary={constructor.Nombre} />
                            <ListItemSecondaryAction>
                                <Switch
                                    edge="end"
                                    onChange={() => cambiarActividad(index)}
                                    checked={empleadosConstruccion[index].Activo === 1 ? true : false}
                                    inputProps={{ 'aria-labelledby': 'switch-list-label' }}
                                    color="primary"
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    );
                })
                    : <CircularProgress color="primary" />
                }
            </List>
            <BottomNavigation className={classes.footer}>
                <BottomNavigationAction icon={<PersonAddIcon color='primary' />} onClick={() => window.open('/agregarConstructorMovil','_self')} />
                <BottomNavigationAction icon={<PlaylistAddIcon color='primary' />} onClick={() => window.open('/registroHorasMovil','_self')} />
            </BottomNavigation>
        </Container>
    );
}