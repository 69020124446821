import React from 'react';
import './RevisionHoras.css';
import servidor from '../../../util/servidor';
import { withStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import { Table } from '@material-ui/core';
import { TableHead } from '@material-ui/core';
import { TableBody } from '@material-ui/core';
import { TableRow } from '@material-ui/core';
import { TableCell } from '@material-ui/core';
import { TableContainer } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

const styles = {
    table: {
        width: '80%',
        margin: 'auto'
    },
    head: {
        backgroundColor: "#010326"
    },
    header: {
        color: "#fa6320",
        fontSize: 16,
        paddingLeft: 5,
        paddingRight: 5,
        textAlign: 'center'
    },
    body: {
        padding: '5px 5px',
        textAlign: 'center',
        fontSize: 16
    },
    nombre: {
        minWidth: 220,
        textAlign: 'left',
        paddingLeft: 15
    },
    proyecto: {
        minWidth: 90
    },
    horasTotales: {
        maxWidth: 60
    },
    salarioHora: {
        minWidth: 70
    },
    horasOrdinarias: {
        maxWidth: 80
    },
    salarioHoraExtra: {
        maxWidth: 100
    },
    horasExtra: {
        maxWidth: 50
    },
    salarioHoraDoble: {
        maxWidth: 100
    },
    horasDobles: {
        maxWidth: 60
    },
    devengado: {
        minWidth: 90
    },
    caja: {
        maxWidth: 70
    },
    neto: {
        minWidth: 100
    }
};

class RevisionHoras extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            empleadosConstruccion: [],
        }
        this.getData = this.getData.bind(this);
        this.calcularSalarioDevengado = this.calcularSalarioDevengado.bind(this);
        this.calcularCaja = this.calcularCaja.bind(this);
        this.calcularSalarioNeto = this.calcularSalarioNeto.bind(this);
    }

    async getData () {
        console.log(this.state.empleadosConstruccion);
        let empleadosConstruccion = await servidor.empleadosConstruccionActivos(this.props.token);
        this.setState({empleadosConstruccion: empleadosConstruccion});
        empleadosConstruccion.forEach(async (trabajador,index) => {
            let devengado = await this.calcularSalarioDevengado(trabajador.HorasTrabajo,trabajador.SalarioPorHora);
            let caja = this.calcularCaja(devengado);
            let neto = this.calcularSalarioNeto(devengado, caja);
        this.setState({[`trabajador-${index}`]: {devengado: devengado, caja: caja, neto: neto}})
        });
    }

    async componentDidMount () {
        if (this.state.empleadosConstruccion.length === 0) {
            await this.getData();
        }
    }

    calcularSalarioDevengado(horas, salarioPorHora) {
        let ordinarias = 0;
        let extras = 0;
        let dobles = 0;
        let salarioDevengado;
        
        if  (horas > 60) {
          dobles = horas - 60;
          extras = 12;
          ordinarias = 48;
        } else if (horas <= 60 && horas > 48) {
          extras = horas - 48;
          ordinarias = 48;
        } else if (horas <= 48) {
          ordinarias = horas;
        }
        
        salarioDevengado = salarioPorHora*2*dobles + salarioPorHora*1.5*extras + salarioPorHora*ordinarias;
        return salarioDevengado;
    }

    calcularCaja(devengado) {
        let caja = devengado*0.105;
        return caja;
    }

    calcularSalarioNeto (devengado, caja) {
        var neto = devengado - caja;
        return neto;
    }

    render () {
        const { classes } = this.props;
        return (
            <div className="RevisionHoras">
                <div className="HeaderRevision">
                    <Button variant="contained" color="primary" href="/registroHoras">Regresar</Button>
                    <Button variant="contained" color="primary" href="/planillaConstruccion">Continuar</Button>
                </div>
                <TableContainer component={Paper} className={classes.table}>
                    <Table size="medium">
                        <TableHead className={classes.head}>
                            <TableRow>
                                <TableCell className={`${classes.header} ${classes.nombre}`}>Nombre</TableCell>
                                <TableCell className={`${classes.header} ${classes.proyecto}`}>Proyecto</TableCell>
                                <TableCell className={`${classes.header} ${classes.horasTotales}`}>Horas Totales</TableCell>
                                <TableCell className={`${classes.header} ${classes.salarioHora}`}>Salario Hora</TableCell>
                                <TableCell className={`${classes.header} ${classes.horasOrdinarias}`}>Horas Ordinarias</TableCell>
                                <TableCell className={`${classes.header} ${classes.salarioHoraExtra}`}>Salario Hora Extra</TableCell>
                                <TableCell className={`${classes.header} ${classes.horasExtra}`}>Horas Extra</TableCell>
                                <TableCell className={`${classes.header} ${classes.salarioHoraDoble}`}>Salario Hora Doble</TableCell>
                                <TableCell className={`${classes.header} ${classes.horasDobles}`}>Horas Dobles</TableCell>
                                <TableCell className={`${classes.header} ${classes.devengado}`}>Salario Devengado</TableCell>
                                <TableCell className={`${classes.header} ${classes.caja}`}>C.C.S.S. 10.50%</TableCell>
                                <TableCell className={`${classes.header} ${classes.neto}`}>Salario Neto</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.empleadosConstruccion.map((trabajador, index) => {
                                return (
                                    <TableRow hover={true} key={`constructor-${index}`}>
                                        <TableCell className={`${classes.body} ${classes.nombre}`}>{trabajador.Nombre}</TableCell>
                                        <TableCell className={`${classes.body} ${classes.proyecto}`}>{trabajador.Proyecto}</TableCell>
                                        <TableCell className={`${classes.body} ${classes.horasTotales}`}>{trabajador.HorasTrabajo}</TableCell>
                                        <TableCell className={`${classes.body} ${classes.salarioHora}`}>{(trabajador.SalarioPorHora).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</TableCell>
                                        <TableCell className={`${classes.body} ${classes.horasOrdinarias}`}>{trabajador.HorasTrabajo > 48 ? 48 : trabajador.HorasTrabajo}</TableCell>
                                        <TableCell className={`${classes.body} ${classes.salarioHoraExtra}`}>{(trabajador.SalarioPorHora*1.5).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</TableCell>
                                        <TableCell className={`${classes.body} ${classes.horasExtra}`}>{trabajador.HorasTrabajo <= 48 ? 0 : (trabajador.HorasTrabajo >= 60 ? 12 : trabajador.HorasTrabajo - 48)}</TableCell>
                                        <TableCell className={`${classes.body} ${classes.salarioHoraDoble}`}>{(trabajador.SalarioPorHora*2).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</TableCell>
                                        <TableCell className={`${classes.body} ${classes.horasDobles}`}>{trabajador.HorasTrabajo > 60 ? trabajador.HorasTrabajo-60 : 0}</TableCell>
                                        <TableCell className={`${classes.body} ${classes.devengado}`}>{this.state[`trabajador-${index}`] ? (this.state[`trabajador-${index}`].devengado).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : null}</TableCell>
                                        <TableCell className={`${classes.body} ${classes.caja}`}>{this.state[`trabajador-${index}`] ? (this.state[`trabajador-${index}`].caja).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : null}</TableCell>
                                        <TableCell className={`${classes.body} ${classes.neto}`}>{this.state[`trabajador-${index}`] ? (this.state[`trabajador-${index}`].neto).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : null}</TableCell>
                                    </TableRow>);
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }
}

export default withStyles(styles)(RevisionHoras);