import React from 'react';
import './Constructores.css';
import servidor from '../../util/servidor';
import './Constructores.scss';
import { withStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import { Table } from '@material-ui/core';
import { TableHead } from '@material-ui/core';
import { TableBody } from '@material-ui/core';
import { TableRow } from '@material-ui/core';
import { TableCell } from '@material-ui/core';
import { TableContainer } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import HelpIcon from '@material-ui/icons/Help';
import Popover from '@material-ui/core/Popover';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { IconButton } from '@material-ui/core';

const styles = {
    table: {
        width: 'fit-content',
        margin: 'auto'
    },
    head: {
        backgroundColor: "#010326"
    },
    header: {
        color: "#fa6320",
        fontSize: 18,
        paddingLeft: 5,
        paddingRight: 5,
        textAlign: 'center'
    },
    body: {
        padding: '0 5px',
        textAlign: 'center',
        fontSize: 16
    },
    popover: {
        width: 500
    },
    tipoId: {
        minWidth: 85,
        paddingLeft: 5
    },
    id: {
        minWidth: 120
    },
    nombre: {
        minWidth: 260,
        textAlign: 'left'
    },
    tel: {
        minWidth: 80
    },
    puesto: {
        minWidth: 80
    },
    ingreso: {
        minWidth: 90
    },
    egreso: {
        minWidth: 90
    },
    salarioporhora: {
        minWidth: 150
    },
    rebajos: {
        minWidth: 120
    },
    activo: {
        minWidth: 40
    },
    icono: {
        minWidth: 20
    },
}

class Constructores extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            empleadosConstruccion: [],
            anchor: null
        }
        this.getData = this.getData.bind(this);
        this.cambiarActividad = this.cambiarActividad.bind(this);
        this.modificarConstructor = this.modificarConstructor.bind(this);
        this.popHelp = this.popHelp.bind(this);
        this.borrarConstructor = this.borrarConstructor.bind(this);
    }

    async getData() {
        let empleadosConstruccion = await servidor.empleadosConstruccion(this.props.token);
        await empleadosConstruccion.sort((a, b) => {
            return b.Activo - a.Activo;
        });
        let actividad = [];
        await empleadosConstruccion.map(trabajador => {
            actividad.push(trabajador.Activo);
            return trabajador;
        });
        this.setState({ actividad: actividad });
        this.setState({ empleadosConstruccion: empleadosConstruccion });
    }

    async componentDidMount() {
        if (this.state.empleadosConstruccion.length === 0) {
            await this.getData();
        }
    }

    cambiarActividad(e) {
        let index = e.target.id.substring(4, 6);
        let activo = (this.state.actividad[index] === 1 ? 0 : 1);
        let id = this.state.empleadosConstruccion[index].Id;
        let params = {
            activo: activo,
            id: id
        };
        servidor.cambiarActividad(this.props.token, params);
        this.setState({ actividad: { ...this.state.actividad, [index]: activo } });
    }

    modificarConstructor(event) {
        window.open(`/agregarConstructor?id=${event.target.id}`, '_self');
    }

    borrarConstructor(index) {
        servidor.borrarConstructor(this.props.token, {id: this.state.empleadosConstruccion[index].Id})
        .then(() => this.getData())
    }

    popHelp(event) {
        this.setState({ anchor: (this.state.anchor ? null : event.currentTarget) });
    }

    render() {
        const { classes } = this.props;
        return (
            <div className="Constructores">
                <div className="HeaderConstructores">
                    <Button variant="contained" color="primary" href="/AgregarConstructor">Añadir trabajador</Button>
                    <Button variant="contained" color="primary" href="/registroHoras">Planilla nueva</Button>
                </div>
                <TableContainer component={Paper} className={classes.table}>
                    <Table size="small" className={classes.table}>
                        <TableHead className={classes.head}>
                            <TableRow>
                                <TableCell className={`${classes.header} ${classes.tipoId}`}>
                                    <Popover open={Boolean(this.state.anchor)} anchorEl={this.state.anchor} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} transformOrigin={{ vertical: 'top', horizontal: 'left' }} onClose={this.popHelp} disableRestoreFocus>
                                        <h3 style={{ marginBottom: 4, paddingLeft: 10 }}>Tipos de identificación:</h3>
                                        <ul style={{ marginTop: 4, marginRight: 10 }}>
                                            <li>CN = Cédula Nacional</li>
                                            <li>CR = Cédula de Residencia, Régimen Especial, Refugiado</li>
                                            <li>DU = Documento Único, DIMEX, DIDI</li>
                                            <li>NP = Pasaporte</li>
                                            <li>PT = Permiso de Trabajo</li>
                                            <li>SD = Sin documentos</li>
                                        </ul>
                                    </Popover>
                                    <HelpIcon onClick={this.popHelp} id="Help-Icon" color="primary" style={{ fontSize: 16, cursor: 'pointer' }} />
                                    Tipo Id
                                </TableCell>
                                <TableCell className={`${classes.header} ${classes.id}`}>Identificación</TableCell>
                                <TableCell className={`${classes.header} ${classes.nombre}`} >Nombre</TableCell>
                                <TableCell className={`${classes.header} ${classes.tel}`}>Teléfono</TableCell>
                                <TableCell className={`${classes.header} ${classes.puesto}`}>Puesto</TableCell>
                                <TableCell className={`${classes.header} ${classes.ingreso}`}>Ingreso</TableCell>
                                <TableCell className={`${classes.header} ${classes.egreso}`}>Egreso</TableCell>
                                <TableCell className={`${classes.header} ${classes.salarioporhora}`}>Salario Por Hora</TableCell>
                                <TableCell className={`${classes.header} ${classes.rebajos}`}>Rebajos</TableCell>
                                <TableCell className={`${classes.header} ${classes.activo}`}>Activo</TableCell>
                                <TableCell className={`${classes.header} ${classes.icono}`}></TableCell>
                                <TableCell className={`${classes.header} ${classes.icono}`}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.empleadosConstruccion.map((trabajador, index) => {
                                return (
                                    <TableRow hover={true} key={`constructor-${index}`}>
                                        <TableCell className={`${classes.body} ${classes.tipoId}`}>
                                            <span style={{ width: 40 }}></span>
                                            {trabajador.TipoId}
                                        </TableCell>
                                        <TableCell className={`${classes.body} ${classes.id}`}>{trabajador.id}</TableCell>
                                        <TableCell className={`${classes.body} ${classes.nombre}`}>{trabajador.nombre}</TableCell>
                                        <TableCell className={`${classes.body} ${classes.tel}`}>{trabajador.tel}</TableCell>
                                        <TableCell className={`${classes.body} ${classes.puesto}`}>{trabajador.puesto}</TableCell>
                                        <TableCell className={`${classes.body} ${classes.ingreso}`}>{trabajador.fechaingreso === 'null' ? '' : trabajador.fechaingreso}</TableCell>
                                        <TableCell className={`${classes.body} ${classes.egreso}`}>{trabajador.fechaegreso === 'null' ? '' : trabajador.fechaegreso}</TableCell>
                                        <TableCell className={`${classes.body} ${classes.salarioporhora}`}>{(trabajador.salarioporhora).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</TableCell>
                                        <TableCell className={`${classes.body} ${classes.rebajos}`}>{trabajador.Rebajos}</TableCell>
                                        <TableCell className={`${classes.body} ${classes.activo}`}>
                                            <Switch size='small' checked={this.state.actividad[index] === 1 ? true : false} onChange={this.cambiarActividad} color="primary" id={`cbx-${index}`} />
                                        </TableCell>
                                        <TableCell className={`${classes.body} ${classes.icono}`}>
                                            <IconButton aria-label="edit" size="small" href={`/modificarConstructor/${trabajador.Id}`}>
                                                <EditIcon id="Edit-Icon" color="primary" style={{ fontSize: 24, cursor: 'pointer' }} />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell className={`${classes.body} ${classes.icono}`}>
                                            <IconButton id={`borrar-${index}`} onClick={() => this.borrarConstructor(index)} aria-label="delete" size="small" >
                                                <DeleteForeverIcon color="primary" style={{ fontSize: 24, cursor: 'pointer' }} />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }
}

export default withStyles(styles)(Constructores);