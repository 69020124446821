import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { Table } from '@material-ui/core';
import { TableHead } from '@material-ui/core';
import { TableBody } from '@material-ui/core';
import { TableRow } from '@material-ui/core';
import { TableCell } from '@material-ui/core';
import { TableContainer } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import ScheduleIcon from '@material-ui/icons/Schedule';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import { IconButton } from '@material-ui/core';
import { Dialog } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { Button } from '@material-ui/core';
import servidor from '../../util/servidor';

const useStyles = makeStyles(() => ({
    planillasAnteriores: {
        marginTop: 30
    },
    table: {
        width: 'fit-content',
        margin: 'auto',
        marginTop: 20
    },
    head: {
        backgroundColor: "#010326"
    },
    header: {
        color: "#fa6320",
        fontSize: 20,
        paddingLeft: 5,
        paddingRight: 5,
        textAlign: 'center'
    },
    body: {
        padding: '0 5px',
        textAlign: 'center',
        fontSize: 18
    },
    mesInicio: {
        minWidth: 85,
        paddingLeft: 20
    },
    diaInicio: {
        minWidth: 60
    },
    anoInicio: {
        minWidth: 70,
    },
    hasta: {
        minWidth: 80,
        fontWeight: 500
    },
    mesFinal: {
        minWidth: 85
    },
    diaFinal: {
        minWidth: 60
    },
    anoFinal: {
        minWidth: 70,
        paddingRight: 10
    },
    icono: {
        minWidth: 20
    },
}));

export default function PlanillasAnteriores(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [onDelete, setOnDelete] = useState(0);
    const [planillas, setPlanillas] = useState([]);
    //codigo sacado de los faqs de React
    useEffect(() => {
        let ignore = false;

        async function getPlanillasAnteriores() {
            if (!ignore) {
                let response = await servidor.getPlanillasConstruccion(props.token);
                response.sort((a,b) => {
                    return b.planilla_id - a.planilla_id;
                })
                setPlanillas(response);
            }
        }
        getPlanillasAnteriores();
        return () => { ignore = true; }
    }, [props.token]);

    function confimarBorrado(id) {
        setOnDelete(id);
        setOpen(true);
    };

    async function borrarPlanilla(id) {
        let params = { id: id };
        let response = await servidor.borrarPlanillaConstruccion(props.token, params);
        console.log(response);
        window.location.reload();
    };

    function handleClose() {
        setOpen(false);
    };

    function verPlanilla(id, di, mi, ai, df, mf, af) {
        window.open(`/verPlanillaConstruccion/${id}/${di}/${mi}/${ai}/${df}/${mf}/${af}`, '_self');
    };

    function verPlanillaCaja(id, di, mi, ai, df, mf, af) {
        window.open(`/verPlanillaConstruccionCaja/${id}/${di}/${mi}/${ai}/${df}/${mf}/${af}`, '_self');
    };

    function verRegistroRevisionHoras(id) {
        window.open(`/verRegistroRevisionHoras/${id}`, '_self');
    };

    return (
        <Container className={classes.planillasAnteriores}>
            <Typography variant="h3" align="left" color="secondary">Planillas Construcción</Typography>
            <TableContainer component={Paper} className={classes.table}>
                <Table size="small">
                    <TableHead className={classes.head}>
                        <TableRow>
                            <TableCell className={`${classes.header} ${classes.hasta}`}>Desde</TableCell>
                            <TableCell className={`${classes.header} ${classes.mesInicio}`}>Mes Inicio</TableCell>
                            <TableCell className={`${classes.header} ${classes.diaInicio}`} >Día Inicio</TableCell>
                            <TableCell className={`${classes.header} ${classes.anoInicio}`}>Año Inicio</TableCell>
                            <TableCell className={`${classes.header} ${classes.hasta}`}>hasta</TableCell>
                            <TableCell className={`${classes.header} ${classes.mesFinal}`}>Mes Final</TableCell>
                            <TableCell className={`${classes.header} ${classes.diaFinal}`}>Día Final</TableCell>
                            <TableCell className={`${classes.header} ${classes.anoFinal}`}>Año Final</TableCell>
                            <TableCell className={`${classes.header} ${classes.icono}`}></TableCell>
                            <TableCell className={`${classes.header} ${classes.icono}`}></TableCell>
                            <TableCell className={`${classes.header} ${classes.icono}`}></TableCell>
                            <TableCell className={`${classes.header} ${classes.icono}`}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {planillas.length > 0 ? planillas.map((planilla) => {
                            return (
                                <TableRow hover={true} key={`planilla-${planilla.planilla_id}`}>
                                    <TableCell className={`${classes.body} ${classes.hasta}`}>Desde</TableCell>
                                    <TableCell className={`${classes.body} ${classes.mesInicio}`}>{planilla.mesinicio}</TableCell>
                                    <TableCell className={`${classes.body} ${classes.diaInicio}`}>{planilla.diainicio}</TableCell>
                                    <TableCell className={`${classes.body} ${classes.anoInicio}`}>{planilla.anoinicio}</TableCell>
                                    <TableCell className={`${classes.body} ${classes.hasta}`}>hasta</TableCell>
                                    <TableCell className={`${classes.body} ${classes.mesFinal}`}>{planilla.mesfinal}</TableCell>
                                    <TableCell className={`${classes.body} ${classes.diaFinal}`}>{planilla.diafinal}</TableCell>
                                    <TableCell className={`${classes.body} ${classes.anoFinal}`}>{planilla.anofinal}</TableCell>
                                    <TableCell className={`${classes.body} ${classes.icono}`}>
                                        <IconButton id={`ver-${planilla.planilla_id}`} onClick={() => verPlanillaCaja(planilla.planilla_id, planilla.diainicio, planilla.mesinicio, planilla.anoinicio, planilla.diafinal, planilla.mesfinal, planilla.anofinal)} aria-label="planilla" size="small" >
                                            <AssignmentLateIcon color="primary" style={{ fontSize: 24, cursor: 'pointer' }} />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell className={`${classes.body} ${classes.icono}`}>
                                        <IconButton id={`ver-${planilla.planilla_id}`} onClick={() => verPlanilla(planilla.planilla_id, planilla.diainicio, planilla.mesinicio, planilla.anoinicio, planilla.diafinal, planilla.mesfinal, planilla.anofinal)} aria-label="planilla" size="small" >
                                            <VisibilityIcon color="primary" style={{ fontSize: 24, cursor: 'pointer' }} />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell className={`${classes.body} ${classes.icono}`}>
                                        <IconButton id={`verHoras-${planilla.planilla_id}`} onClick={() => verRegistroRevisionHoras(planilla.planilla_id)} aria-label="registroHoras" size="small" >
                                            <ScheduleIcon color="primary" style={{ fontSize: 24, cursor: 'pointer' }} />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell className={`${classes.body} ${classes.icono}`}>
                                        <IconButton id={`borrar-${planilla.planilla_id}`} onClick={() => confimarBorrado(planilla.planilla_id)} aria-label="delete" size="small" >
                                            <DeleteForeverIcon color="primary" style={{ fontSize: 24, cursor: 'pointer' }} />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })
                            : null}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog onClose={handleClose} open={open}>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        ¿Desea borrar este registro de planilla?
                        </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        No
                    </Button>
                    <Button onClick={() => borrarPlanilla(onDelete)} color="primary">
                        Sí
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}