import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { SwipeableDrawer } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListAltSharpIcon from '@material-ui/icons/ListAltSharp';
import CloudIcon from '@material-ui/icons/Cloud';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: 10,
        position: 'sticky',
        top: 0,
        zIndex: 5
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

export default function HeaderMovil(props) {
    const [openDrawer, setOpenDrawer] = useState(false);
    const classes = useStyles();

    function cambiarPagina(loc) {
        window.open(loc, '_self');
    }

    return (
        <div className={classes.root}>
            <AppBar position="static" color="secondary">
                <Toolbar>
                    <IconButton edge="start" className={classes.menuButton} color="primary" aria-label="menu" onClick={() => setOpenDrawer(/*!openDrawer*/false)}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title} color="primary">
                        ICSA Planillas
                    </Typography>
                    <Button color="primary" onClick={props.signOut}>Salir</Button>
                </Toolbar>
            </AppBar>
            <SwipeableDrawer classes={{ paper: classes.paper }} anchor='left' open={openDrawer} onClose={() => setOpenDrawer(!openDrawer)} onOpen={() => {}}>
                <AppBar position="static" color="secondary">
                    <Toolbar>
                        <Typography variant="h6" className={classes.title} color="primary">
                            Menu
                        </Typography>
                    </Toolbar>
                </AppBar>
                <List component="nav" aria-label="main mailbox folders" style={{ width: 400 }}>
                    <ListItem button onClick={() => cambiarPagina("/")}>
                        <ListItemIcon>
                            <ListAltSharpIcon color="primary" style={{ fontSize: 40 }} />
                        </ListItemIcon>
                        <ListItemText classes={{ primary: classes.primary }} primary="Planilla Construcción" />
                    </ListItem>
                    <ListItem button onClick={() => cambiarPagina("/")} divider={true}>
                        <ListItemIcon>
                            <ListAltSharpIcon color="primary" style={{ fontSize: 40 }} />
                        </ListItemIcon>
                        <ListItemText classes={{ primary: classes.primary }} primary="Planilla Administrativa" />
                    </ListItem>
                    <ListItem button onClick={() => cambiarPagina("/")}>
                        <ListItemIcon>
                            <CloudIcon color="primary" style={{ fontSize: 40 }} />
                        </ListItemIcon>
                        <ListItemText classes={{ primary: classes.primary }} primary="Planillas Anteriores" />
                    </ListItem>
                </List>
            </SwipeableDrawer>
        </div>
    );
}