const url='https://icsa-server.herokuapp.com/'

const servidor = {
    async empleadosConstruccion(token) {
        //return fetch('http://localhost:8080/empleadosConstruccion', {
        return fetch(`${url}empleadosConstruccion`, {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }).then((response) => {
            return response.json();
        }).then(jsonResponse => {
            console.log(jsonResponse);
            return jsonResponse;
        })
    },

    async empleadosConstruccionActivos(token) {
        //return fetch('http://localhost:8080/empleadosConstruccionActivos', {
        return fetch('https://servidor.icsacr.com/empleadosConstruccionActivos', {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }).then((response) => {
            return response.json();
        }).then(jsonResponse => {
            console.log(jsonResponse);
            return jsonResponse;
        })
    },

    async cambiarActividad(token, params) {
        //return fetch('http://localhost:8080/cambiarActividad', {
        return fetch('https://servidor.icsacr.com/cambiarActividad', {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }).then(response => console.log(response));
    },

    async registroHoras(token, params) {
        //const response = await fetch('http://localhost:8080/registrarHoras', {
        const response = await fetch('https://servidor.icsacr.com/registrarHoras', {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        })
        console.log(response);
    },

    async registroHorasMovil(token, params) {
        //const response = await fetch('http://localhost:8080/registrarHorasMovil', {
        const response = await fetch('https://servidor.icsacr.com/registrarHorasMovil', {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        })
        return response;
    },

    async guardarPlanillaConstruccion(token, params) {
        //return fetch('http://localhost:8080/guardarPlanillaConstruccion', {
        return fetch('https://servidor.icsacr.com/guardarPlanillaConstruccion', {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }).then(response => {
            return response;
        });
    },

    async agregarConstructor(token, params) {
        console.log(params);
        //return fetch('http://localhost:8080/agregarConstructor', {
        return fetch('https://servidor.icsacr.com/agregarConstructor', {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }).then(response => {return response});
    },

    async actualizarConstructor(token, params) {
        console.log(params);
        //return fetch('http://localhost:8080/actualizarConstructor', {
        return fetch('https://servidor.icsacr.com/actualizarConstructor', {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }).then(response => {
            return response;
        });
    },

    async getConstructor(token, params) {
        //return fetch('http://localhost:8080/getConstructor', {
        return fetch('https://servidor.icsacr.com/getConstructor', {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }).then((response) => {
            return response.json();
        }).then(jsonResponse => {
            console.log(jsonResponse);
            return jsonResponse;
        })
    },

    async borrarConstructor(token, params) {
        //return fetch('http://localhost:8080/borrarConstructor', {
        return fetch('https://servidor.icsacr.com/borrarConstructor', {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }).then((response) => {
            return response.json();
        }).then(jsonResponse => {
            console.log(jsonResponse);
            return jsonResponse;
        })
    },

    async empleadosAdministrativos(token) {
        //return fetch('http://localhost:8080/empleadosAdministrativos', {
        return fetch('https://servidor.icsacr.com/empleadosAdministrativos', {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }).then((response) => {
            return response.json();
        }).then(jsonResponse => {
            console.log(jsonResponse);
            return jsonResponse;
        })
    },

    async empleadosAdministrativosActivos(token) {
        //return fetch('http://localhost:8080/empleadosAdministrativosActivos', {
        return fetch('https://servidor.icsacr.com/empleadosAdministrativosActivos', {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }).then((response) => {
            return response.json();
        }).then(jsonResponse => {
            console.log(jsonResponse);
            return jsonResponse;
        })
    },

    async agregarAdministrativo(token, params) {
        console.log(params);
        //return fetch('http://localhost:8080/agregarAdministrativo', {
        return fetch('https://servidor.icsacr.com/agregarAdministrativo', {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }).then(response => console.log(response));
    },

    async getAdministrativo(token, params) {
        //return fetch('http://localhost:8080/getAdministrativo', {
        return fetch('https://servidor.icsacr.com/getAdministrativo', {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }).then((response) => {
            return response.json();
        }).then(jsonResponse => {
            console.log(jsonResponse);
            return jsonResponse;
        })
    },

    async actualizarAdministrativo(token, params) {
        console.log(params);
        //return fetch('http://localhost:8080/actualizarAdministrativo', {
        return fetch('https://servidor.icsacr.com/actualizarAdministrativo', {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }).then(response => {
            return response;
        });
    },

    async borrarAdministrativo(token, params) {
        //return fetch('http://localhost:8080/borrarAdministrativo', {
        return fetch('https://servidor.icsacr.com/borrarAdministrativo', {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }).then((response) => {
            return response.json();
        }).then(jsonResponse => {
            console.log(jsonResponse);
            return jsonResponse;
        })
    },

    async getPlanillasConstruccion(token) {
        //return fetch('http://localhost:8080/planillasConstruccion', {
        return fetch(`${url}planillasConstruccion`, {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }).then((response) => {
            return response.json();
        }).then(jsonResponse => {
            console.log(jsonResponse);
            return jsonResponse;
        })
    },

    async borrarPlanillaConstruccion(token, params) {
        //return fetch('http://localhost:8080/borrarPlanillaConstruccion', {
        return fetch('https://servidor.icsacr.com/borrarPlanillaConstruccion', {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }).then((response) => {
            return response.json();
        }).then(jsonResponse => {
            console.log(jsonResponse);
            return jsonResponse;
        })
    },

    async datosPlanillaConstruccion(params) {
        //return fetch('http://localhost:8080/planillas_empleados_construccion/getPlanillaConstruccion', {
        return fetch(`${url}planillas_empleados_construccion/getPlanillaConstruccion`, {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                "Content-type": "application/json"
            }
        }).then((response) => {
            return response.json();
        }).then(jsonResponse => {
            console.log(jsonResponse);
            return jsonResponse;
        })
    },
}

export default servidor;