import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import CreateIcon from '@material-ui/icons/Create';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button } from '@material-ui/core';
import { Dialog } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import servidor from '../../util/servidor';

const useStyles = makeStyles((theme) => ({
    verConstructorMovil: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        height: '-webkit-fill-available',
        paddingBottom: 56
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    lista: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: '#fafcfc',
        flexGrow: 1,
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    valor: {
        fontSize: '1.3rem'
    },
    footer: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#010326',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '100%'
    },
    spinner: {
        display: 'flex',
        alignSelf: 'center',
        justifySelf: 'center'
    }
}));

const id = {
    CN: 'Cédula Nacional',
    CR: 'Cédula de Residencia, Régimen Especial o Refugiado',
    DU: 'Documento Único, DIMEX o DIDI',
    NP: 'Pasaporte',
    PT: 'Permiso de Trabajo',
    SD: 'Sin documentos'
}

export default function VerConstructorMovil(props) {
    const classes = useStyles();
    const [openAlert, setOpenAlert] = useState(false);
    const [constructor, setConstructor] = useState([]);
    const [tipoIdentificacion, setTipoIdentificacion] = useState('');
    //codigo sacado de los faqs de React
    useEffect(() => {
        let ignore = false;

        async function getConstructor() {
            if (!ignore) {
                let response = await servidor.getConstructor(props.token, { id: props.id });
                let constructor = response[0];
                setConstructor(constructor);
                setTipoIdentificacion(id[constructor.TipoId])
            }
        }
        getConstructor();
        return () => { ignore = true; }
    }, [props.token, props.id]);

    function cambiarActividad() {
        let activo = (constructor.Activo === 1 ? 0 : 1);
        let id = constructor.Id;
        let params = {
            activo: activo,
            id: id
        };
        servidor.cambiarActividad(props.token, params);
        setConstructor(constructor => ({ ...constructor, Activo: activo }));
    }

    function handleClose() {
        setOpenAlert(false);
    }

    function borrarConstructor() {
        servidor.borrarConstructor(props.token, { id: props.id })
        .then(() => window.open('/','_self'));
    }

    return (
        <Container className={classes.verConstructorMovil}>
            {constructor.Id ?
                <List className={classes.lista}>
                    <ListItem key='Nombre' className={classes.item}>
                        <Typography align='left' variant='overline'>Nombre</Typography>
                        <ListItemText classes={{ primary: classes.valor }} id="switch-list-label-constructor" primary={constructor.Nombre} />
                        <ListItemSecondaryAction>
                            <Switch
                                edge="end"
                                onChange={cambiarActividad}
                                checked={constructor.Activo === 1 ? true : false}
                                inputProps={{ 'aria-labelledby': 'switch-list-label' }}
                                color="primary"
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem key='TipoId' className={classes.item}>
                        <Typography align='left' variant='overline'>Tipo de identificación</Typography>
                        <ListItemText classes={{ primary: classes.valor }} id="switch-list-label-constructor" primary={(constructor.TipoId ? `${constructor.TipoId} - ${tipoIdentificacion}` : '')} />
                    </ListItem>
                    <ListItem key='Id' className={classes.item}>
                        <Typography align='left' variant='overline'>Id</Typography>
                        <ListItemText classes={{ primary: classes.valor }} id="Id" primary={constructor.Id} />
                    </ListItem>
                    <ListItem key='Tel' className={classes.item}>
                        <Typography align='left' variant='overline'>Teléfono</Typography>
                        <ListItemText classes={{ primary: classes.valor }} id="Tel" primary={constructor.Tel} />
                    </ListItem>
                    <ListItem key='Puesto' className={classes.item}>
                        <Typography align='left' variant='overline'>Puesto</Typography>
                        <ListItemText classes={{ primary: classes.valor }} id="Puesto" primary={constructor.Puesto} />
                    </ListItem>
                    <ListItem key='Cuenta' className={classes.item}>
                        <Typography align='left' variant='overline'>Cuenta</Typography>
                        <ListItemText classes={{ primary: classes.valor }} id="Cuenta" primary={constructor.Cuenta} />
                    </ListItem>
                    <ListItem key='Ingreso' className={classes.item}>
                        <Typography align='left' variant='overline'>Fecha de Ingreso</Typography>
                        <ListItemText classes={{ primary: classes.valor }} id="Ingreso" primary={constructor.FechaIngreso} />
                    </ListItem>
                    <ListItem key='Salario' className={classes.item}>
                        <Typography align='left' variant='overline'>Salario por hora</Typography>
                        <ListItemText classes={{ primary: classes.valor }} id="Salario" primary={(constructor.SalarioPorHora ? '₡' + (constructor.SalarioPorHora).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0)} />
                    </ListItem>
                    <ListItem key='Rebajos' className={classes.item}>
                        <Typography align='left' variant='overline'>Rebajos</Typography>
                        <ListItemText classes={{ primary: classes.valor }} id="Rebajos" primary={constructor.Rebajos} />
                    </ListItem>
                </List>
                : <CircularProgress color="primary" className={classes.spinner} />
            }
            <BottomNavigation className={classes.footer}>
                <BottomNavigationAction icon={<ArrowBackIcon color='primary' />} onClick={() => window.open('/', '_self')} />
                <BottomNavigationAction icon={<DeleteIcon color='primary' />} onClick={() => setOpenAlert(true)} />
                <BottomNavigationAction icon={<CreateIcon color='primary' />} onClick={() => window.open(`/ModificarConstructorMovil/${props.id}`, '_self')} />
            </BottomNavigation>

            <Dialog onClose={handleClose} open={openAlert}>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-alert">
                        ¿Desea borrar este trabajador?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        No
                    </Button>
                    <Button onClick={borrarConstructor} color="primary">
                        Sí
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}