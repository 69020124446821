import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import './PlanillaConstruccion.css';
import servidor from "../../../util/servidor";
import { Dialog, Container } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Table } from '@material-ui/core';
import { TableHead } from '@material-ui/core';
import { TableBody } from '@material-ui/core';
import { TableRow } from '@material-ui/core';
import { TableCell } from '@material-ui/core';
import { TableContainer } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Input } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

const styles = {
    planillaConstruccion: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: 'auto',
        backgroundColor: '#fafcfc',
        minHeight: '-webkit-fill-available',
        paddingBottom: '20px'
    },
    fecha: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '20px',
        fontFamily: "'Noto Sans KR', sans-serif",
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: '40px'
    },
    inputFecha: {
        height: '30px',
        width: '30px',
        color: 'black',
        margin: '4px',
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 20
    },
    inputDia: {
        height: '30px',
        width: '90px',
        color: 'black',
        margin: '4px',
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 20
    },
    etiquetaFecha: {
        fontFamily: "'Noto Sans KR', sans-serif",
        fontWeight: 'bold',
        margin: 'auto 0',
        textAlign: 'center',
        fontSize: '20px',
    },
    table: {
        width: 'fit-content',
        margin: 'auto',
        marginTop: 40
    },
    head: {
        backgroundColor: "#010326"
    },
    header: {
        color: "#fa6320",
        fontSize: 18,
        paddingLeft: 5,
        paddingRight: 5,
        textAlign: 'center'
    },
    body: {
        padding: '4px 5px',
        textAlign: 'center',
        fontSize: 16
    },
    total: {
        padding: '4px 5px',
        textAlign: 'center',
        fontSize: 16,
        fontWeight: 500
    },
    id: {
        minWidth: 120
    },
    nombre: {
        minWidth: 260,
        textAlign: 'left'
    },
    proyecto: {
        minWidth: 80
    },
    puesto: {
        minWidth: 80
    },
    salarioDevengado: {
        minWidth: 90
    },
    caja: {
        minWidth: 90
    },
    salarioNeto: {
        minWidth: 150
    },
    footerPlanilla: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'row',
        margin: '10px auto',
        width: '50%',
        padding: '20px',
        justifyContent: 'space-around'
    }
}

class PlanillaConstruccion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            empleadosConstruccion: [],
            open: false,
            meses: ["enero", "febrero", "marzo", "abril", "mayo", "junio",
                "julio", "agosto", "setiembre", "octubre", "noviembre", "diciembre"],
            diaInicio: new Date().getDate(),
            diaFinal: new Date().getDate(),
            diaInicioFecha: new Date(),
            diaFinalFecha: new Date(),
            mesInicio: '',
            mesFinal: '',
            anoInicio: new Date().getFullYear(),
            anoFinal: new Date().getFullYear()
        }
        this.getData = this.getData.bind(this);
        this.calcularSalarioDevengado = this.calcularSalarioDevengado.bind(this);
        this.calcularCaja = this.calcularCaja.bind(this);
        this.calcularSalarioNeto = this.calcularSalarioNeto.bind(this);
        this.cambioFechaInicio = this.cambioFechaInicio.bind(this);
        this.cambioFechaFinal = this.cambioFechaFinal.bind(this);
        this.cambioMesInicio = this.cambioMesInicio.bind(this);
        this.cambioMesFinal = this.cambioMesFinal.bind(this);
        this.cambioAnoInicio = this.cambioAnoInicio.bind(this);
        this.cambioAnoFinal = this.cambioAnoFinal.bind(this);
        this.guardarPlanilla = this.guardarPlanilla.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.calcularFechaInicio = this.calcularFechaInicio.bind(this);
    }

    async getData() {
        console.log(this.state.empleadosConstruccion);
        let empleadosConstruccion = await servidor.empleadosConstruccionActivos(this.props.token);
        this.setState({ empleadosConstruccion: empleadosConstruccion });
        let devengadoTotal = 0;
        let cajaTotal = 0;
        let netoTotal = 0;
        empleadosConstruccion.forEach((trabajador, index) => {
            let devengado = this.calcularSalarioDevengado(trabajador.HorasTrabajo, trabajador.SalarioPorHora);
            let caja = this.calcularCaja(devengado);
            let neto = this.calcularSalarioNeto(devengado, caja);
            devengadoTotal = devengadoTotal + devengado;
            cajaTotal = cajaTotal + caja;
            netoTotal = netoTotal + neto;
            this.setState({ [`trabajador-${index}`]: { devengado: devengado, caja: caja, neto: neto } })
        })
        this.setState({
            devengadoTotal: devengadoTotal,
            cajaTotal: cajaTotal,
            netoTotal: netoTotal
        });
    }

    async componentDidMount() {
        this.calcularFechaInicio();
        this.setState({
            mesInicio: this.state.meses[new Date().getMonth()],
            mesFinal: this.state.meses[new Date().getMonth()]
        })
        if (this.state.empleadosConstruccion.length === 0) {
            await this.getData();
        }
    }

    calcularSalarioDevengado(horas, salarioPorHora) {
        let ordinarias = 0;
        let extras = 0;
        let dobles = 0;
        let salarioDevengado;

        if (horas > 60) {
            dobles = horas - 60;
            extras = 12;
            ordinarias = 48;
        } else if (horas <= 60 && horas > 48) {
            extras = horas - 48;
            ordinarias = 48;
        } else if (horas <= 48) {
            ordinarias = horas;
        }

        salarioDevengado = salarioPorHora * 2 * dobles + salarioPorHora * 1.5 * extras + salarioPorHora * ordinarias;
        return salarioDevengado;
    }

    calcularCaja(devengado) {
        let caja = devengado * 0.105;
        return caja;
    }

    calcularSalarioNeto(devengado, caja) {
        var neto = devengado - caja;
        return neto;
    }

    cambioFechaInicio(date) {
        document.getElementById('inputMesInicio').value = this.state.meses[date.getMonth()];
        this.setState({
            diaInicio: date.getDate(),
            mesInicio: this.state.meses[date.getMonth()],
            diaInicioFecha: date
        });
    }

    cambioFechaFinal(date) {
        document.getElementById('inputMesFinal').value = this.state.meses[date.getMonth()];
        this.setState({
            diaFinal: date.getDate(),
            mesFinal: this.state.meses[date.getMonth()],
            diaFinalFecha: date
        });
    }

    cambioMesInicio(e) {
        this.setState({ mesInicio: e.target.value });
    }

    cambioMesFinal(e) {
        this.setState({ mesFinal: e.target.value });
    }

    cambioAnoInicio(e) {
        this.setState({ anoInicio: e.target.value });
    }

    cambioAnoFinal(e) {
        this.setState({ anoFinal: e.target.value });
    }

    handleClose() {
        this.setState({ open: false });
    }

    calcularFechaInicio () {
        let hoy = new Date();
        let dia = hoy.getDay();
        let unDia = 24*60*60*1000;
        let fechaInicio = '';
        let fechaFinal = '';
        if (dia === 1) {
            fechaInicio = hoy.getTime() - 3 * unDia;
            fechaFinal = hoy.getTime() + 3 * unDia;
            this.setState({
                diaInicioFecha: new Date(fechaInicio),
                diaFinalFecha: new Date(fechaFinal),
                diaInicio: new Date(fechaInicio).getDate(),
                diaFinal: new Date(fechaFinal).getDate()
            });
        } else if (dia === 2) {
            fechaInicio = hoy.getTime() - 4 * unDia;
            fechaFinal = hoy.getTime() + 2 * unDia;
            this.setState({
                diaInicioFecha: new Date(fechaInicio),
                diaFinalFecha: new Date(fechaFinal),
                diaInicio: new Date(fechaInicio).getDate(),
                diaFinal: new Date(fechaFinal).getDate()
            });
        } else if (dia === 3) {
            fechaInicio = hoy.getTime() - 5 * unDia;
            fechaFinal = hoy.getTime() + 1 * unDia;
            this.setState({
                diaInicioFecha: new Date(fechaInicio),
                diaFinalFecha: new Date(fechaFinal),
                diaInicio: new Date(fechaInicio).getDate(),
                diaFinal: new Date(fechaFinal).getDate()
            });
        } else if (dia === 4) {
            fechaInicio = hoy.getTime() - 6 * unDia;
            fechaFinal = hoy.getTime() + 0 * unDia;
            this.setState({
                diaInicioFecha: new Date(fechaInicio),
                diaFinalFecha: new Date(fechaFinal),
                diaInicio: new Date(fechaInicio).getDate(),
                diaFinal: new Date(fechaFinal).getDate()
            });
        } else if (dia === 5) {
            fechaInicio = hoy.getTime() - 7 * unDia;
            fechaFinal = hoy.getTime() - 1 * unDia;
            this.setState({
                diaInicioFecha: new Date(fechaInicio),
                diaFinalFecha: new Date(fechaFinal),
                diaInicio: new Date(fechaInicio).getDate(),
                diaFinal: new Date(fechaFinal).getDate()
            });
        } else if (dia === 6) {
            fechaInicio = hoy.getTime() - 8 * unDia;
            fechaFinal = hoy.getTime() - 2 * unDia;
            this.setState({
                diaInicioFecha: new Date(fechaInicio),
                diaFinalFecha: new Date(fechaFinal),
                diaInicio: new Date(fechaInicio).getDate(),
                diaFinal: new Date(fechaFinal).getDate()
            });
        } else if (dia === 7) {
            fechaInicio = hoy.getTime() - 9 * unDia;
            fechaFinal = hoy.getTime() - 3 * unDia;
            this.setState({
                diaInicioFecha: new Date(fechaInicio),
                diaFinalFecha: new Date(fechaFinal),
                diaInicio: new Date(fechaInicio).getDate(),
                diaFinal: new Date(fechaFinal).getDate()
            });
        }
    }

    async guardarPlanilla() {
        if (!this.state.diaInicio || !this.state.diaFinal) {
            this.setState({ open: true });
        } else {
            let params = {
                planilla: {
                    diaInicio: this.state.diaInicio,
                    diaFinal: this.state.diaFinal,
                    mesInicio: this.state.mesInicio,
                    mesFinal: this.state.mesFinal,
                    anoInicio: this.state.anoInicio,
                    anoFinal: this.state.anoFinal
                },
                constructores: this.state.empleadosConstruccion.map(constructor => {
                    return {
                        constructor_id: constructor.Id,
                        nombre: constructor.Nombre,
                        horasTrabajadas: constructor.HorasTrabajo,
                        salarioPorHora: constructor.SalarioPorHora,
                        proyecto: constructor.Proyecto,
                        puesto: constructor.Puesto
                    }
                })
            };

            servidor.guardarPlanillaConstruccion(this.props.token, params)
                .then(() => window.open('/', "_self"));
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <Container className={classes.planillaConstruccion}>
                <Container className={classes.fecha}>
                    <Typography className={classes.etiquetaFecha}>Planilla de campo del </Typography>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            className={classes.inputDia}
                            variant="inline"
                            value={this.state.diaInicioFecha}
                            margin='normal'
                            format='d'
                            onChange={this.cambioFechaInicio}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    <Typography className={classes.etiquetaFecha}>de</Typography>
                    <Input id='inputMesInicio' defaultValue={this.state.meses[new Date().getMonth()]} style={{ minWidth: 80 }} className={classes.inputFecha} type="text" onChange={this.cambioMesInicio}></Input>
                    <Typography className={classes.etiquetaFecha}>del</Typography>
                    <Input defaultValue={new Date().getFullYear()} style={{ minWidth: 60 }} className={classes.inputFecha} type="number" onChange={this.cambioAnoInicio} required></Input>
                    <Typography className={classes.etiquetaFecha}>al</Typography>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            className={classes.inputDia}
                            variant="inline"
                            value={this.state.diaFinalFecha}
                            margin="normal"
                            format='d'
                            onChange={this.cambioFechaFinal}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    <Typography className={classes.etiquetaFecha}>de</Typography>
                    <Input id='inputMesFinal' defaultValue={this.state.meses[new Date().getMonth()]} style={{ minWidth: 80 }} className={classes.inputFecha} type="text" onChange={this.cambioMesFinal} required></Input>
                    <Typography className={classes.etiquetaFecha}>del</Typography>
                    <Input defaultValue={new Date().getFullYear()} style={{ minWidth: 60 }} className={classes.inputFecha} type="number" onChange={this.cambioAnoFinal} required></Input>
                </Container>
                <TableContainer component={Paper} className={classes.table}>
                    <Table>
                        <TableHead className={classes.head}>
                            <TableRow>
                                <TableCell className={`${classes.header} ${classes.id}`}>Identificación</TableCell>
                                <TableCell className={`${classes.header} ${classes.nombre}`}>Nombre</TableCell>
                                <TableCell className={`${classes.header} ${classes.proyecto}`}>Proyecto</TableCell>
                                <TableCell className={`${classes.header} ${classes.puesto}`}>Puesto</TableCell>
                                <TableCell className={`${classes.header} ${classes.salarioDevengado}`}>Salario Devengado</TableCell>
                                <TableCell className={`${classes.header} ${classes.caja}`}>C.C.S.S. 10.50%</TableCell>
                                <TableCell className={`${classes.header} ${classes.salarioNeto}`}>Salario Neto</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.empleadosConstruccion.map((trabajador, index) => {
                                return (
                                    <TableRow hover={true} key={`constructor-${index}`}>
                                        <TableCell className={`${classes.body} ${classes.id}`}>{trabajador.Id}</TableCell>
                                        <TableCell className={`${classes.body} ${classes.nombre}`}>{trabajador.Nombre}</TableCell>
                                        <TableCell className={`${classes.body} ${classes.proyecto}`}>{trabajador.Proyecto}</TableCell>
                                        <TableCell className={`${classes.body} ${classes.puesto}`}>{trabajador.Puesto}</TableCell>
                                        <TableCell className={`${classes.body} ${classes.salarioDevengado}`}>{this.state[`trabajador-${index}`] ? (this.state[`trabajador-${index}`].devengado).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : null}</TableCell>
                                        <TableCell className={`${classes.body} ${classes.caja}`}>{this.state[`trabajador-${index}`] ? (this.state[`trabajador-${index}`].caja).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : null}</TableCell>
                                        <TableCell className={`${classes.body} ${classes.salarioNeto}`}>{this.state[`trabajador-${index}`] ? (this.state[`trabajador-${index}`].neto).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : null}</TableCell>
                                    </TableRow>);
                            })}
                            <TableRow hover={true}>
                                <TableCell className={`${classes.total} ${classes.id}`}>Total</TableCell>
                                <TableCell className={`${classes.total} ${classes.nombre}`}></TableCell>
                                <TableCell className={`${classes.total} ${classes.proyecto}`}></TableCell>
                                <TableCell className={`${classes.total} ${classes.puesto}`}></TableCell>
                                <TableCell className={`${classes.total} ${classes.salarioDevengado}`}>{this.state.devengadoTotal ? (this.state.devengadoTotal).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : null}</TableCell>
                                <TableCell className={`${classes.total} ${classes.caja}`}>{this.state.cajaTotal ? (this.state.cajaTotal).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : null}</TableCell>
                                <TableCell className={`${classes.total} ${classes.salarioNeto}`}>{this.state.netoTotal ? (this.state.netoTotal).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : null}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <Container className={classes.footerPlanilla}>
                    <Button href="/revisionHoras" variant="contained" color="primary">Regresar</Button>
                    <Button variant="contained" color="primary" onClick={this.guardarPlanilla}>Terminar</Button>
                </Container>
                <Dialog onClose={this.handleClose} open={this.state.open}>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Recuerde completar la fecha de la planilla.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        );
    }
}

export default withStyles(styles)(PlanillaConstruccion);