import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import servidor from '../../util/servidor';

const useStyles = makeStyles((theme) => ({
    constructoresMovil: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        height: '-webkit-fill-available',
        marginBottom: '4.5rem'
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    lista: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: '#fafcfc',
        flexGrow: 1
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    },
    inputs: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    nombre: {
        fontSize: '1.2rem'
    },
    footer: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#010326',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '100%'
    }
}));

export default function RegistroHorasMovil(props) {
    const classes = useStyles();
    const [empleadosConstruccion, setEmpleadosConstruccion] = useState([]);
    //codigo sacado de los faqs de React
    useEffect(() => {
        let ignore = false;

        async function getempleadosConstruccion() {
            if (!ignore) {
                let response = await servidor.empleadosConstruccionActivos(props.token);
                setEmpleadosConstruccion(response);
            }
        }
        getempleadosConstruccion();
        return () => { ignore = true; }
    }, [props.token]);

    function cambiarProyecto(e, index) {
        let nuevoEmpleadosConstruccion = [...empleadosConstruccion];
        nuevoEmpleadosConstruccion[index].Proyecto = e.target.value;
        setEmpleadosConstruccion(nuevoEmpleadosConstruccion);
    }
    function cambiarHoras(e, index) {
        let nuevoEmpleadosConstruccion = [...empleadosConstruccion];
        nuevoEmpleadosConstruccion[index].HorasTrabajo = e.target.value;
        setEmpleadosConstruccion(nuevoEmpleadosConstruccion);
    }

    async function registrarHoras() {
        let response;
        let params = {
            id: '',
            proyecto: '',
            horas: 0
        }
        //Así sí sirve el await!
        for (let constructor of empleadosConstruccion) {
            params['id'] = constructor.Id;
            params['proyecto'] = constructor.Proyecto;
            params['horas'] = constructor.HorasTrabajo;

            response = await servidor.registroHorasMovil(props.token, params);
            console.log(response);
        }
    }

    async function finalizarRegistro() {
        await registrarHoras();
        window.open('/planillaConstruccionMovil', '_self');
    }

    return (
        <Container className={classes.constructoresMovil}>
            <List subheader={<ListSubheader style={{ textAlign: 'left' }}>Registro de horas</ListSubheader>} className={classes.lista}>
                {empleadosConstruccion.length > 0 ? empleadosConstruccion.map((constructor, index) => {
                    return (
                        <ListItem key={constructor.Id} className={classes.item}>
                            <ListItemText classes={{ primary: classes.nombre }} primary={constructor.Nombre} />
                            <Container className={classes.inputs}>
                                <TextField style={{ marginRight: 8 }} value={constructor.Proyecto} onChange={(e) => cambiarProyecto(e, index)} label="Proyecto" />
                                <TextField value={constructor.HorasTrabajo} onChange={(e) => cambiarHoras(e, index)} label="Horas" />
                            </Container>
                        </ListItem>
                    );
                })
                    : <CircularProgress color="primary" />
                }
            </List>
            <BottomNavigation className={classes.footer}>
                <BottomNavigationAction icon={<ArrowBackIcon color='primary' />} onClick={() => window.open('/', '_self')} />
                <BottomNavigationAction icon={<ArrowForwardIcon color='primary' />} onClick={finalizarRegistro} />
            </BottomNavigation>
        </Container>
    );
}