import React from 'react';
import './PlanillaConstruccionAnterior.css';
import { Link } from "react-router-dom";
import servidor from "../../util/servidor";

export class PlanillaConstruccionAnteriorCaja extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            empleadosConstruccion: []
        }
        this.getData = this.getData.bind(this);
        this.calcularSalarioDevengado = this.calcularSalarioDevengado.bind(this);
        this.calcularCaja = this.calcularCaja.bind(this);
        this.calcularSalarioNeto = this.calcularSalarioNeto.bind(this);
    }

    async componentDidMount() {
        if (this.state.empleadosConstruccion.length === 0) {
            await this.getData();
        }
    }

    async getData() {
        let params = { id: this.props.id };
        let empleadosConstruccion = await servidor.datosPlanillaConstruccion(params);
        this.setState({ empleadosConstruccion: empleadosConstruccion });
        let devengadoTotal = 0;
        let cajaTotal = 0;
        let netoTotal = 0;
        empleadosConstruccion.forEach((trabajador) => {
            let devengado = this.calcularSalarioDevengado(trabajador.horastrabajadas, trabajador.salarioporhora);
            let caja = this.calcularCaja(devengado);
            let neto = this.calcularSalarioNeto(devengado, caja);
            devengadoTotal = devengadoTotal + devengado;
            cajaTotal = cajaTotal + caja;
            netoTotal = netoTotal + neto;
            this.setState({ [`trabajador-${trabajador.constructor_id}`]: { devengado: devengado, caja: caja, neto: neto } })
        })
        this.setState({
            devengadoTotal: devengadoTotal,
            cajaTotal: cajaTotal,
            netoTotal: netoTotal
        });
    }

    calcularSalarioDevengado(horas, salarioPorHora) {
        let ordinarias = 0;
        let salarioDevengado;

        if (horas > 60) {
            ordinarias = 48;
        } else if (horas <= 60 && horas > 48) {
            ordinarias = 48;
        } else if (horas <= 48) {
            ordinarias = horas;
        }

        salarioDevengado = salarioPorHora * ordinarias;
        return salarioDevengado;
    }

    calcularCaja(devengado) {
        let caja = devengado * 0.105;
        return caja;
    }

    calcularSalarioNeto(devengado, caja) {
        var neto = devengado - caja;
        return neto;
    }

    render() {
        return (
            <div className="PlanillaConstruccionAnterior">
                <div className="Fecha">
                    <p className="EtiquetaFecha">Planilla de campo del {this.props.di} de {this.props.mi} del {this.props.ai} al {this.props.df} de {this.props.mf} del {this.props.af}</p>
                </div>
                <div className="ListaConstructoresPlanillaFinal">
                    <ul className="EtiquetasTitulosPlanilla">
                        <p className="EtiquetaTituloPlanilla" style={{ minWidth: 150 }}>Identificación</p>
                        <p className="EtiquetaTituloPlanilla" style={{ minWidth: 220, textAlign: 'left' }}>Nombre</p>
                        <p className="EtiquetaTituloPlanilla" style={{ minWidth: 110 }}>Proyecto</p>
                        <p className="EtiquetaTituloPlanilla" style={{ minWidth: 130 }}>Puesto</p>
                        <p className="EtiquetaTituloPlanilla" style={{ minWidth: 150 }}>Salario Devengado</p>
                        <p className="EtiquetaTituloPlanilla" style={{ minWidth: 140 }}>C.C.S.S. 10.50%</p>
                        <p className="EtiquetaTituloPlanilla" style={{ minWidth: 115 }}>Salario Neto</p>
                    </ul>

                    {this.state.empleadosConstruccion.map((trabajador) => {
                        return (
                            <ul className="ConstructorPlanilla" key={trabajador.constructor_id}>
                                <p className="EtiquetaPlanilla" style={{ minWidth: 150 }}>{trabajador.constructor_id}</p>
                                <p className="EtiquetaPlanilla" style={{ minWidth: 220, textAlign: 'left' }}>{trabajador.nombre}</p>
                                <p className="EtiquetaPlanilla" style={{ minWidth: 110 }}>{trabajador.proyecto}</p>
                                <p className="EtiquetaPlanilla" style={{ minWidth: 130 }}>{trabajador.puesto}</p>
                                <p className="EtiquetaPlanilla" style={{ minWidth: 150 }}>{this.state[`trabajador-${trabajador.constructor_id}`] ? (this.state[`trabajador-${trabajador.constructor_id}`].devengado).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : null}</p>
                                <p className="EtiquetaPlanilla" style={{ minWidth: 140 }}>{this.state[`trabajador-${trabajador.constructor_id}`] ? (this.state[`trabajador-${trabajador.constructor_id}`].caja).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : null}</p>
                                <p className="EtiquetaPlanilla" style={{ minWidth: 115 }}>{this.state[`trabajador-${trabajador.constructor_id}`] ? (this.state[`trabajador-${trabajador.constructor_id}`].neto).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : null}</p>
                            </ul>);
                    })}
                    <ul className="ConstructorPlanilla">
                        <p className="EtiquetaPlanilla" style={{ minWidth: 150, fontWeight: 'bold', textAlign: 'center' }}>Total</p>
                        <p className="EtiquetaPlanilla" style={{ minWidth: 220 }}></p>
                        <p className="EtiquetaPlanilla" style={{ minWidth: 110 }}></p>
                        <p className="EtiquetaPlanilla" style={{ minWidth: 130 }}></p>
                        <p className="EtiquetaPlanilla" style={{ fontWeight: 'bold', minWidth: 150 }}>{this.state.devengadoTotal ? (this.state.devengadoTotal).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : null}</p>
                        <p className="EtiquetaPlanilla" style={{ fontWeight: 'bold', minWidth: 140 }}>{this.state.cajaTotal ? (this.state.cajaTotal).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : null}</p>
                        <p className="EtiquetaPlanilla" style={{ fontWeight: 'bold', minWidth: 115 }}>{this.state.netoTotal ? (this.state.netoTotal).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : null}</p>
                    </ul>
                </div>
                <div className="HeaderPlanilla">
                    <Link to="/planillasAnteriores" className="BotonPlanilla">Regresar</Link>
                    <button className="BotonPlanilla" onClick={window.print} style={{ cursor: 'pointer' }}>Imprimir</button>
                </div>
            </div>
        );
    }
}