import React from 'react';
import servidor from '../../util/servidor';
import { withStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import { Table } from '@material-ui/core';
import { TableHead } from '@material-ui/core';
import { TableBody } from '@material-ui/core';
import { TableRow } from '@material-ui/core';
import { TableCell } from '@material-ui/core';
import { TableContainer } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import HelpIcon from '@material-ui/icons/Help';
import Popover from '@material-ui/core/Popover';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { IconButton } from '@material-ui/core';
import { Container } from '@material-ui/core';

const styles = {
    administrativos: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        maxWidth: 2000
    },
    headerAdministrativos: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        margin: 20,
        width: '80%'
    },
    table: {
        width: 'fit-content',
        margin: 'auto'
    },
    head: {
        backgroundColor: "#010326"
    },
    header: {
        color: "#fa6320",
        fontSize: 18,
        paddingLeft: 5,
        paddingRight: 5,
        textAlign: 'center'
    },
    body: {
        padding: '0 5px',
        textAlign: 'center',
        fontSize: 16
    },
    popover: {
        width: 500
    },
    tipoId: {
        minWidth: 85,
        paddingLeft: 5
    },
    id: {
        minWidth: 130
    },
    nombre: {
        minWidth: 280,
        textAlign: 'left'
    },
    tel: {
        minWidth: 150
    },
    puesto: {
        minWidth: 100
    },
    ingreso: {
        minWidth: 90
    },
    egreso: {
        minWidth: 90
    },
    salarioPorHora: {
        minWidth: 150
    },
    rebajos: {
        minWidth: 120
    },
    activo: {
        minWidth: 40
    },
    icono: {
        minWidth: 20
    },
}

class Administrativos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            empleadosAdministrativos: [],
            anchor: null
        }
        this.getData = this.getData.bind(this);
        this.cambiarActividad = this.cambiarActividad.bind(this);
        this.modificarAdministrativo = this.modificarAdministrativo.bind(this);
        this.popHelp = this.popHelp.bind(this);
        this.borrarAdministrativo = this.borrarAdministrativo.bind(this);
    }

    async getData() {
        let empleadosAdministrativos = await servidor.empleadosAdministrativos(this.props.token);
        await empleadosAdministrativos.sort((a, b) => {
            return b.Activo - a.Activo;
        });
        let actividad = [];
        await empleadosAdministrativos.map(trabajador => {
            actividad.push(trabajador.Activo);
            return trabajador;
        });
        this.setState({ actividad: actividad });
        this.setState({ empleadosAdministrativos: empleadosAdministrativos });
    }

    async componentDidMount() {
        if (this.state.empleadosAdministrativos.length === 0) {
            await this.getData();
        }
    }

    cambiarActividad(e) {
        let index = e.target.id.substring(4, 6);
        let activo = (this.state.actividad[index] === 1 ? 0 : 1);
        let id = this.state.empleadosAdministrativos[index].Id;
        let params = {
            activo: activo,
            id: id
        };
        servidor.cambiarActividad(this.props.token, params);
        this.setState({ actividad: { ...this.state.actividad, [index]: activo } });
    }

    modificarAdministrativo(event) {
        window.open(`/agregarAdministrativo?id=${event.target.id}`, '_self');
    }

    borrarAdministrativo(index) {
        servidor.borrarAdministrativo(this.props.token, {id: this.state.empleadosAdministrativos[index].Id});
    }

    popHelp(event) {
        this.setState({ anchor: (this.state.anchor ? null : event.currentTarget) });
    }

    render() {
        const { classes } = this.props;
        return (
            <Container className={classes.administrativos}>
                <Container className={classes.headerAdministrativos}>
                    <Button variant="contained" color="primary" href="/agregarAdministrativo">Añadir trabajador</Button>
                    <Button variant="contained" color="primary" href="/planillaAdministrativa">Planilla nueva</Button>
                </Container>
                <TableContainer component={Paper} className={classes.table}>
                    <Table size="small" className={classes.table}>
                        <TableHead className={classes.head}>
                            <TableRow>
                                <TableCell className={`${classes.header} ${classes.tipoId}`}>
                                    <Popover open={Boolean(this.state.anchor)} anchorEl={this.state.anchor} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} transformOrigin={{ vertical: 'top', horizontal: 'left' }} onClose={this.popHelp} disableRestoreFocus>
                                        <h3 style={{ marginBottom: 4, paddingLeft: 10 }}>Tipos de identificación:</h3>
                                        <ul style={{ marginTop: 4, marginRight: 10 }}>
                                            <li>CN = Cédula Nacional</li>
                                            <li>CR = Cédula de Residencia, Régimen Especial, Refugiado</li>
                                            <li>DU = Documento Único, DIMEX, DIDI</li>
                                            <li>NP = Pasaporte</li>
                                            <li>PT = Permiso de Trabajo</li>
                                            <li>SD = Sin documentos</li>
                                        </ul>
                                    </Popover>
                                    <HelpIcon onClick={this.popHelp} id="Help-Icon" color="primary" style={{ fontSize: 16, cursor: 'pointer' }} />
                                    Tipo Id
                                </TableCell>
                                <TableCell className={`${classes.header} ${classes.id}`}>Identificación</TableCell>
                                <TableCell className={`${classes.header} ${classes.nombre}`} >Nombre</TableCell>
                                <TableCell className={`${classes.header} ${classes.tel}`}>Teléfono</TableCell>
                                <TableCell className={`${classes.header} ${classes.puesto}`}>Puesto</TableCell>
                                <TableCell className={`${classes.header} ${classes.ingreso}`}>Ingreso</TableCell>
                                <TableCell className={`${classes.header} ${classes.egreso}`}>Egreso</TableCell>
                                <TableCell className={`${classes.header} ${classes.salarioPorHora}`}>Salario Nominal</TableCell>
                                <TableCell className={`${classes.header} ${classes.rebajos}`}>Rebajos</TableCell>
                                <TableCell className={`${classes.header} ${classes.activo}`}>Activo</TableCell>
                                <TableCell className={`${classes.header} ${classes.icono}`}></TableCell>
                                <TableCell className={`${classes.header} ${classes.icono}`}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.empleadosAdministrativos.map((trabajador, index) => {
                                return (
                                    <TableRow hover={true} key={`administrativo-${index}`}>
                                        <TableCell className={`${classes.body} ${classes.tipoId}`}>
                                            <span style={{ width: 40 }}></span>
                                            {trabajador.TipoId}
                                        </TableCell>
                                        <TableCell className={`${classes.body} ${classes.id}`}>{trabajador.Id}</TableCell>
                                        <TableCell className={`${classes.body} ${classes.nombre}`}>{trabajador.Nombre}</TableCell>
                                        <TableCell className={`${classes.body} ${classes.tel}`}>{trabajador.Tel}</TableCell>
                                        <TableCell className={`${classes.body} ${classes.puesto}`}>{trabajador.Puesto}</TableCell>
                                        <TableCell className={`${classes.body} ${classes.ingreso}`}>{trabajador.FechaIngreso === 'null' ? '' : trabajador.FechaIngreso}</TableCell>
                                        <TableCell className={`${classes.body} ${classes.egreso}`}>{trabajador.FechaEgreso === 'null' ? '' : trabajador.FechaEgreso}</TableCell>
                                        <TableCell className={`${classes.body} ${classes.salarioNominal}`}>{(trabajador.SalarioNominal).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</TableCell>
                                        <TableCell className={`${classes.body} ${classes.rebajos}`}>{trabajador.Rebajos}</TableCell>
                                        <TableCell className={`${classes.body} ${classes.activo}`}>
                                            <Switch size='small' checked={this.state.actividad[index] === 1 ? true : false} onChange={this.cambiarActividad} color="primary" id={`cbx-${index}`} />
                                        </TableCell>
                                        <TableCell className={`${classes.body} ${classes.icono}`}>
                                            <IconButton aria-label="edit" size="small" href={`/modificarAdministrativo/${trabajador.Id}`}>
                                                <EditIcon id="Edit-Icon" color="primary" style={{ fontSize: 24, cursor: 'pointer' }} />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell className={`${classes.body} ${classes.icono}`}>
                                            <IconButton id={`borrar-${index}`} onClick={() => this.borrarAdministrativo(index)} aria-label="delete" size="small" >
                                                <DeleteForeverIcon color="primary" style={{ fontSize: 24, cursor: 'pointer' }} />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        );
    }
}

export default withStyles(styles)(Administrativos);