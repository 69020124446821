import React from 'react';
import './PlanillaAdministrativa.css';
import { Link } from "react-router-dom";
import servidor from "../../../util/servidor";
import { Dialog } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { Button } from '@material-ui/core';

export class PlanillaAdministrativa extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            empleadosAdministrativos: [],
            open: false,
            meses: ["enero", "febrero", "marzo", "abril", "mayo", "junio",
                "julio", "agosto", "setiembre", "octubre", "noviembre", "diciembre"],
            mes: '',
            ano: new Date().getFullYear()
        }
        this.getData = this.getData.bind(this);
        this.calcularSalarioDevengado = this.calcularSalarioDevengado.bind(this);
        this.calcularCaja = this.calcularCaja.bind(this);
        this.calcularSalarioNeto = this.calcularSalarioNeto.bind(this);
        this.cambioFechaInicio = this.cambioFechaInicio.bind(this);
        this.cambioFechaFinal = this.cambioFechaFinal.bind(this);
        this.cambioMes = this.cambioMes.bind(this);
        this.cambioAno = this.cambioAno.bind(this);
        this.guardarPlanilla = this.guardarPlanilla.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    async getData() {
        console.log(this.state.empleadosAdministrativos);
        let empleadosAdministrativos = await servidor.empleadosAdministrativosActivos(this.props.token);
        this.setState({ empleadosAdministrativos: empleadosAdministrativos });
        let devengadoTotal = 0;
        let cajaTotal = 0;
        let netoTotal = 0;
        empleadosAdministrativos.forEach((trabajador, index) => {
            let devengado = this.calcularSalarioDevengado(trabajador.HorasTrabajo, trabajador.SalarioPorHora);
            let caja = this.calcularCaja(devengado);
            let neto = this.calcularSalarioNeto(devengado, caja);
            devengadoTotal = devengadoTotal + devengado;
            cajaTotal = cajaTotal + caja;
            netoTotal = netoTotal + neto;
            this.setState({ [`trabajador-${index}`]: { devengado: devengado, caja: caja, neto: neto } })
        })
        this.setState({
            devengadoTotal: devengadoTotal,
            cajaTotal: cajaTotal,
            netoTotal: netoTotal
        });
    }

    async componentDidMount() {
        this.setState({ mes: this.state.meses[new Date().getMonth()], })
        if (this.state.empleadosAdministrativos.length === 0) {
            await this.getData();
        }
    }

    calcularSalarioDevengado(horas, salarioPorHora) {
        let ordinarias = 0;
        let extras = 0;
        let dobles = 0;
        let salarioDevengado;

        if (horas > 60) {
            dobles = horas - 60;
            extras = 12;
            ordinarias = 48;
        } else if (horas <= 60 && horas > 48) {
            extras = horas - 48;
            ordinarias = 48;
        } else if (horas <= 48) {
            ordinarias = horas;
        }

        salarioDevengado = salarioPorHora * 2 * dobles + salarioPorHora * 1.5 * extras + salarioPorHora * ordinarias;
        return salarioDevengado;
    }

    calcularCaja(devengado) {
        let caja = devengado * 0.105;
        return caja;
    }

    calcularSalarioNeto(devengado, caja) {
        var neto = devengado - caja;
        return neto;
    }

    cambioFechaInicio(e) {
        this.setState({ fechaInicio: e.target.value });
    }

    cambioFechaFinal(e) {
        this.setState({ fechaFinal: e.target.value });
    }

    cambioMes(e) {
        this.setState({ mes: e.target.value });
    }

    cambioAno(e) {
        this.setState({ ano: e.target.value });
    }

    handleClose() {
        this.setState({ open: false });
    }

    guardarPlanilla() {
        if (!this.state.fechaInicio || !this.state.fechaFinal) {
            this.setState({ open: true });
        } else {
            let params = [];
            for (var i = 0; i < this.state.empleadosAdministrativos.length; i++) {
                let constructor = {
                    fechaInicio: (this.state.fechaInicio ? this.state.fechaInicio : 0),
                    fechaFinal: (this.state.fechaFinal ? this.state.fechaFinal : 0),
                    mes: this.state.mes,
                    ano: this.state.ano,
                    id: this.state.empleadosAdministrativos[i].Id,
                    nombre: this.state.empleadosAdministrativos[i].Nombre,
                    proyecto: this.state.empleadosAdministrativos[i].Proyecto,
                    salario: this.state[`trabajador-${i}`].devengado,
                    caja: this.state[`trabajador-${i}`].caja,
                    neto: this.state[`trabajador-${i}`].neto
                };
                params.push(constructor);
            }
            console.log(params);
            servidor.guardarPlanilla(this.props.token, params);
            window.open('/', "_self");
        }

    }

    render() {
        return (
            <div className="PlanillaAdministrativa">
                <div className="Fecha">
                    <p className="EtiquetaFecha">Planilla de campo del </p>
                    <input style={{ minWidth: 30 }} className="inputFecha" type="number" onChange={this.cambioFechaInicio} required></input>
                    <p className="EtiquetaFecha">al</p>
                    <input style={{ minWidth: 30 }} className="inputFecha" type="number" onChange={this.cambioFechaFinal} required></input>
                    <p className="EtiquetaFecha">de</p>
                    <input defaultValue={this.state.meses[new Date().getMonth()]} style={{ minWidth: 80 }} className="inputFecha" type="text" onChange={this.cambioMes} required></input>
                    <p className="EtiquetaFecha">del</p>
                    <input defaultValue={new Date().getFullYear()} style={{ minWidth: 60 }} className="inputFecha" type="number" onChange={this.cambioAno} required></input>
                </div>
                <div className="ListaConstructoresPlanilla">
                    <ul className="EtiquetasTitulosPlanilla">
                        <p className="EtiquetaTituloPlanilla" style={{ minWidth: 150 }}>Identificación</p>
                        <p className="EtiquetaTituloPlanilla" style={{ minWidth: 250, textAlign: 'left' }}>Nombre</p>
                        <p className="EtiquetaTituloPlanilla" style={{ minWidth: 130 }}>Proyecto</p>
                        <p className="EtiquetaTituloPlanilla" style={{ minWidth: 130 }}>Puesto</p>
                        <p className="EtiquetaTituloPlanilla" style={{ minWidth: 150 }}>Salario Devengado</p>
                        <p className="EtiquetaTituloPlanilla" style={{ minWidth: 140 }}>C.C.S.S. 10.50%</p>
                        <p className="EtiquetaTituloPlanilla" style={{ minWidth: 115 }}>Salario Neto</p>
                    </ul>

                    {this.state.empleadosAdministrativos.map((trabajador, index) => {
                        return (
                            <ul className="ConstructorPlanilla" key={index}>
                                <p className="EtiquetaPlanilla" style={{ minWidth: 150 }}>{trabajador.Id}</p>
                                <p className="EtiquetaPlanilla" style={{ minWidth: 250, textAlign: 'left' }}>{trabajador.Nombre}</p>
                                <p className="EtiquetaPlanilla" style={{ minWidth: 130 }}>{trabajador.Proyecto}</p>
                                <p className="EtiquetaPlanilla" style={{ minWidth: 130 }}>{trabajador.Puesto}</p>
                                <p className="EtiquetaPlanilla" style={{ minWidth: 150 }}>{this.state[`trabajador-${index}`] ? (this.state[`trabajador-${index}`].devengado).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : null}</p>
                                <p className="EtiquetaPlanilla" style={{ minWidth: 140 }}>{this.state[`trabajador-${index}`] ? (this.state[`trabajador-${index}`].caja).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : null}</p>
                                <p className="EtiquetaPlanilla" style={{ minWidth: 115 }}>{this.state[`trabajador-${index}`] ? (this.state[`trabajador-${index}`].neto).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : null}</p>
                            </ul>);
                    })}
                    <ul className="Totales">
                        <p className="EtiquetaPlanilla" style={{marginLeft: 50, fontWeight: 'bold'}}>Total</p>
                        <div  className="Totales-sub">
                            <p className="EtiquetaPlanilla" style={{fontWeight: 'bold'}}>{this.state.devengadoTotal ? (this.state.devengadoTotal).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : null}</p>
                            <p className="EtiquetaPlanilla" style={{fontWeight: 'bold'}}>{this.state.cajaTotal ? (this.state.cajaTotal).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : null}</p>
                            <p className="EtiquetaPlanilla" style={{fontWeight: 'bold'}}>{this.state.netoTotal ? (this.state.netoTotal).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : null}</p>
                        </div>
                    </ul>
                </div>
                <div className="HeaderPlanilla">
                    <Link to="/revisionHoras" className="BotonPlanilla">Regresar</Link>
                    <button className="BotonPlanilla" onClick={window.print} style={{ cursor: 'pointer' }}>Imprimir</button>
                    <button className="BotonPlanilla" onClick={this.guardarPlanilla}>Terminar</button>
                </div>
                <Dialog onClose={this.handleClose} open={this.state.open}>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Recuerde completar la fecha de la planilla.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}