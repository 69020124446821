import React from 'react';
import './Header.css';
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import { Link } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import { Drawer } from '@material-ui/core';
import { Toolbar } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListAltSharpIcon from '@material-ui/icons/ListAltSharp';
import CloudIcon from '@material-ui/icons/Cloud';
import Button from '@material-ui/core/Button';
import ReceiptIcon from '@material-ui/icons/Receipt';
import firebase from 'firebase/app';
import 'firebase/auth';

const styles = {
    header: {
        backgroundColor: '#010326',
        //height: '80px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        //fontSize: 'calc(10px + 2vmin)',
        //color: '#f3f3f3',
        zIndex: 10
    },
    headerPlanillas: {
        backgroundColor: 'white',
        height: '80px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: 'calc(10px + 2vmin)',
        color: 'black',
        zIndex: 10
    },
        menuTitulo: {
        display: 'flex',
        flexDirection: 'row'
    },
    paper: {
        backgroundColor: "#fafcfc"
    },
    primary: {
        color: '#fa6320',
        fontSize: 30
    },
    signOut: {
        margin: 'auto 30px'
    }
}

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false
        }
        this.abrirMenu = this.abrirMenu.bind(this);
        this.cambiarPagina = this.cambiarPagina.bind(this);
    }

    abrirMenu() {
        let open = this.state.menu;
        this.setState({ menu: !open });
    }

    cambiarPagina(loc) {
        window.open(loc, '_self');
    }

    render() {
        const { classes } = this.props;

        return (
            <AppBar position='static' className={this.props.location.pathname !== '/planillaAdministrativa' && !this.props.location.pathname.includes('/verPlanillaConstruccion') && !this.props.location.pathname.includes('/verPlanillaAdministrativa') ? classes.header : classes.headerPlanillas}>
                <Toolbar className={classes.menuTitulo}>
                    <MenuIcon className={this.props.location.pathname !== '/planillaAdministrativa' && !this.props.location.pathname.includes('/verPlanillaConstruccion') && !this.props.location.pathname.includes('/verPlanillaAdministrativa') ? "BurgerContainer" : "BurgerContainerPlanillas"} color={this.props.location.pathname !== '/planillaAdministrativa' && !this.props.location.pathname.includes('/verPlanillaConstruccion') && !this.props.location.pathname.includes('/verPlanillaAdministrativa') ? "primary" : "default"} style={{ fontSize: 60 }} onClick={this.abrirMenu}></MenuIcon>
                    <Link to="/" style={{ textDecoration: 'unset' }}><h1 className={this.props.location.pathname !== '/planillaAdministrativa' && !this.props.location.pathname.includes('/verPlanillaConstruccion') && !this.props.location.pathname.includes('/verPlanillaAdministrativa') ? "Titulo" : "TituloPlanillas"}>PLANILLAS</h1></Link>
                </Toolbar>
                <Drawer classes={{ paper: classes.paper }} anchor='left' open={this.state.menu} onClose={this.abrirMenu}>
                    <List component="nav" aria-label="main mailbox folders" style={{ width: 400 }}>
                        <ListItem button onClick={() => this.cambiarPagina("/")}>
                            <ListItemIcon>
                                <ListAltSharpIcon color="primary" style={{ fontSize: 40 }} />
                            </ListItemIcon>
                            <ListItemText classes={{ primary: classes.primary }} primary="Planilla Construcción" />
                        </ListItem>
                        <ListItem button onClick={() => this.cambiarPagina("/administrativos")} divider={true}>
                            <ListItemIcon>
                                <ListAltSharpIcon color="primary" style={{ fontSize: 40 }} />
                            </ListItemIcon>
                            <ListItemText classes={{ primary: classes.primary }} primary="Planilla Administrativa" />
                        </ListItem>
                        <ListItem button onClick={() => this.cambiarPagina("/planillasAnteriores")} divider={true}>
                            <ListItemIcon>
                                <CloudIcon color="primary" style={{ fontSize: 40 }} />
                            </ListItemIcon>
                            <ListItemText classes={{ primary: classes.primary }} primary="Planillas Construcción Registradas" />
                        </ListItem>
                        <ListItem button onClick={() => this.cambiarPagina("https://facturas.icsacr.com")}>
                            <ListItemIcon>
                                <ReceiptIcon color="primary" style={{ fontSize: 40 }} />
                            </ListItemIcon>
                            <ListItemText classes={{ primary: classes.primary }} primary="Facturas" />
                        </ListItem>
                    </List>
                </Drawer>
                {this.props.location.pathname !== '/planillaConstruccion' && this.props.location.pathname !== '/planillaAdministrativa' && !this.props.location.pathname.includes('/verPlanillaConstruccion') && !this.props.location.pathname.includes('/verPlanillaAdministrativa') ?
                    <div>
                        <Button className={classes.signOut} onClick={() => firebase.auth().signOut()} variant="contained" color={"primary"}>Cerrar sesión</Button>
                    </div>
                    :
                    null
                }
            </AppBar>
        );
    }
}

export default withStyles(styles)(withRouter(Header));