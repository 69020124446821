import React from 'react';
import './RegistroHoras.css';
import './RegistroHoras.scss';
import servidor from '../../../util/servidor';
import { withStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import { Table } from '@material-ui/core';
import { TableHead } from '@material-ui/core';
import { TableBody } from '@material-ui/core';
import { TableRow } from '@material-ui/core';
import { TableCell } from '@material-ui/core';
import { TableContainer } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';

const styles = {
    table: {
        width: 'fit-content',
        margin: 'auto'
    },
    head: {
        backgroundColor: "#010326"
    },
    header: {
        color: "#fa6320",
        fontSize: 18,
        paddingLeft: 5,
        paddingRight: 5,
        textAlign: 'center'
    },
    body: {
        padding: '0 5px',
        textAlign: 'center',
        fontSize: 16
    },
    nombre: {
        minWidth: 300,
        textAlign: 'left',
        paddingLeft: 15
    },
    puesto: {
        minWidth: 130
    },
    proyecto: {
        minWidth: 190
    },
    salario: {
        minWidth: 150
    },
    horas: {
        minWidth: 60
    }
};

class RegistroHoras extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            empleadosConstruccion: [],
        }
        this.getData = this.getData.bind(this);
        this.cambioProyecto = this.cambioProyecto.bind(this);
        this.cambioHoras = this.cambioHoras.bind(this);
    }

    async getData () {
        console.log(this.state.empleadosConstruccion);
        let empleadosConstruccion = await servidor.empleadosConstruccionActivos(this.props.token);
        this.setState({empleadosConstruccion: empleadosConstruccion});
    }

    async componentDidMount () {
        if (this.state.empleadosConstruccion.length === 0) {
            await this.getData();
        }
    }

    cambioProyecto(e) {
        let index = e.target.id.substring(9,11);
        let id = this.state.empleadosConstruccion[index].Id;
        let params = {
            campo: 'Proyecto',
            valor: e.target.value,
            id: id
        };
        servidor.registroHoras(this.props.token, params);
    }

    cambioHoras(e) {
        let valor = (e.target.value === '' ? 0 : e.target.value);
        let index = e.target.id.substring(6,8);
        let id = this.state.empleadosConstruccion[index].Id;
        let params = {
            campo: 'HorasTrabajo',
            valor: valor,
            id: id
        };
        servidor.registroHoras(this.props.token, params);
        e.target.value = valor;
    }

    render () {
        const { classes } = this.props;
        return (
            <div className="RegistroHoras">
                <div className="HeaderRegistro">
                    <Button variant="contained" color="primary" href="/">Regresar</Button>
                    <Button variant="contained" color="primary" href="/revisionHoras">Continuar</Button>
                </div>
                <TableContainer component={Paper} className={classes.table}>
                    <Table size="small" className={classes.table}>
                        <TableHead className={classes.head}>
                            <TableRow>
                                <TableCell className={`${classes.header} ${classes.nombre}`} >Nombre</TableCell>
                                <TableCell className={`${classes.header} ${classes.puesto}`}>Puesto</TableCell>
                                <TableCell className={`${classes.header} ${classes.Proyecto}`}>Proyecto</TableCell>
                                <TableCell className={`${classes.header} ${classes.salario}`}>Salario Por Hora</TableCell>
                                <TableCell className={`${classes.header} ${classes.horas}`}>Horas de Trabajo</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {this.state.empleadosConstruccion.map((trabajador, index) => {
                            return (
                            <TableRow hover={true} key={`constructor-${index}`}>
                                <TableCell className={`${classes.body} ${classes.nombre}`}>{trabajador.Nombre}</TableCell>
                                <TableCell className={`${classes.body} ${classes.puesto}`}>{trabajador.Puesto}</TableCell>
                                <Input id={`Proyecto-${index}`} className={`${classes.body} ${classes.proyecto}`} type="text" defaultValue={trabajador.Proyecto} color="primary" onBlur={this.cambioProyecto}/>
                                <TableCell className={`${classes.body} ${classes.salario}`}>{(trabajador.SalarioPorHora).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</TableCell>
                                <Input id={`input-${index}`} className={`${classes.body} ${classes.horas}`} type="number" defaultValue={trabajador.HorasTrabajo !== null ? trabajador.HorasTrabajo : 0} color="primary" onBlur={this.cambioHoras}/>
                            </TableRow>);
                        })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }
}

export default withStyles(styles)(RegistroHoras);