import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, ListItem, InputLabel } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import { Select } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Button } from '@material-ui/core';
import { Dialog } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import servidor from '../../util/servidor';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
    verConstructorMovil: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        height: '-webkit-fill-available',
        paddingBottom: 56
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    lista: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: '#fafcfc',
        flexGrow: 1,
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    footer: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#010326',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '100%'
    },
    spinner: {
        display: 'flex',
        alignSelf: 'center',
        justifySelf: 'center'
    }
}));

export default function AgregarConstructorMovil(props) {
    const classes = useStyles();
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openDeny, setOpenDeny] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [constructor, setConstructor] = useState({
        TipoId: 'CN',
        Id: '',
        Nacionalidad: '',
        Nombre: '',
        Tel: '',
        Puesto: '',
        Salario: 0,
        Rebajos: 'C.C.S.S',
        Cuenta: '',
        FechaIngreso: new Date().getTime(),
    });

    function selectTipoId(e) {
        setConstructor(constructor => ({ ...constructor, TipoId: e.target.value }));
    }

    function handleChange(e) {
        let key = e.target.id;
        let value = e.target.value;
        setConstructor(constructor => ({ ...constructor, [key]: value }));
    }

    function handleDateChange(date) {
        setConstructor(constructor => ({ ...constructor, FechaIngreso: date.getTime() }));
    }

    function handleCloseConfirm() {
        setOpenConfirm(false);
        window.open(`/`, '_self');
    }

    function handleClose() {
        setOpenDeny(false);
        setOpenAlert(false);
    }

    async function agregarConstructor() {
        let requeridos = [
            constructor.Id,
            constructor.Nombre
        ]

        for (var i = 0; i < requeridos.length; i++) {
            if (requeridos[i] === '' || requeridos[i] === 0) {
                setOpenAlert(true);
                return;
            }
        }

        let params = {
            tipoId: constructor.TipoId,
            id: constructor.Id,
            nacionalidad: constructor.Nacionalidad,
            nombre: constructor.Nombre,
            tel: constructor.Tel,
            puesto: constructor.Puesto,
            salario: constructor.Salario,
            rebajos: constructor.Rebajos,
            cuenta: constructor.Cuenta,
            fechaIngreso: (constructor.FechaIngreso).toString(),
        };
        console.log(params);
        
        let response = await servidor.agregarConstructor(props.token, params);
        console.log(response)
        if (response.status === 200) {
            setOpenConfirm(true);
            return;
        } else {
            setOpenDeny(true);
            return;
        }
    }

    return (
        <Container className={classes.verConstructorMovil}>
            <List subheader={<ListSubheader style={{ textAlign: 'left' }}>Nuevo Constructor</ListSubheader>} className={classes.lista}>
                <ListItem key='TipoId' className={classes.item}>
                    <InputLabel style={{ fontSize: 12 }} id='TipoId-label'>Tipo de identificación</InputLabel>
                    <Select labelId="TipoId-label" id='TipoId' value={constructor.TipoId} onChange={selectTipoId}>
                        <MenuItem value={'CN'}>Cédula Nacional</MenuItem>
                        <MenuItem value={'CR'}>Cédula de Residencia, Régimen Especial o Refugiado</MenuItem>
                        <MenuItem value={'DU'}>Documento Único, DIMEX o DIDI</MenuItem>
                        <MenuItem value={'NP'}>Pasaporte</MenuItem>
                        <MenuItem value={'PT'}>Permiso de Trabajo</MenuItem>
                        <MenuItem value={'SD'}>Sin documentos</MenuItem>
                    </Select>
                </ListItem>
                <ListItem key='Id' className={classes.item}>
                    <TextField value={constructor.Id} id='Id' onChange={handleChange} label="Identificación" />
                </ListItem>
                <ListItem key='Nacionalidad' className={classes.item}>
                    <TextField id='Nacionalidad' onChange={handleChange} value={constructor.Nacionalidad} label="Nacionalidad" />
                </ListItem>
                <ListItem key='Nombre' className={classes.item}>
                    <TextField id='Nombre' onChange={handleChange} value={constructor.Nombre} label="Nombre" />
                </ListItem>
                <ListItem key='Tel' className={classes.item}>
                    <TextField id='Tel' onChange={handleChange} value={constructor.Tel} label="Teléfono" />
                </ListItem>
                <ListItem key='Puesto' className={classes.item}>
                    <TextField id='Puesto' onChange={handleChange} value={constructor.Puesto} label="Puesto" />
                </ListItem>
                <ListItem key='SalarioPorHora' className={classes.item}>
                    <TextField id='SalarioPorHora' onChange={handleChange} value={constructor.SalarioPorHora} label="Salario por hora" />
                </ListItem>
                <ListItem key='Rebajos' className={classes.item}>
                    <TextField id='Rebajos' onChange={handleChange} value={constructor.Rebajos} label="Rebajos" />
                </ListItem>
                <ListItem key='Cuenta' className={classes.item}>
                    <TextField id='Cuenta' onChange={handleChange} value={constructor.Cuenta} label="Cuenta" />
                </ListItem>
                <ListItem key='FechaIngreso' className={classes.item}>
                    <InputLabel style={{ fontSize: 12 }} id='FechaIngreso-label'>Fecha de ingreso</InputLabel>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            format="dd/MM/yyyy"
                            margin="normal"
                            value={constructor.FechaIngreso}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </ListItem>
            </List>
            <BottomNavigation className={classes.footer}>
                <BottomNavigationAction icon={<ArrowBackIcon color='primary' />} onClick={() => window.open(`/`, '_self')} />
                <BottomNavigationAction icon={<SaveIcon color='primary' />} onClick={agregarConstructor} />
            </BottomNavigation>

            <Dialog onClose={handleCloseConfirm} open={openConfirm}>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-confirm">
                        El trabajador fue agregado exitosamente.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirm} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog onClose={handleClose} open={openDeny}>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-deny">
                        No se pudo agregar el trabajador. Compruebe su conección a internet.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog onClose={handleClose} open={openAlert}>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-alert">
                        El trabajador debe tener al menos número de identificación y nombre.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}