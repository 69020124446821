import React from 'react';
import './AgregarConstructor.css';
import './AgregarConstructor.scss';
import servidor from '../../../util/servidor';
import HelpIcon from '@material-ui/icons/Help';
import Popover from '@material-ui/core/Popover';
import { Select } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Input } from '@material-ui/core';
import { Container } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Dialog } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

class AgregarConstructor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tipoId: '',
            id: '',
            nacionalidad: '',
            nombre: '',
            tel: '',
            puesto: '',
            salario: 0,
            rebajos: '',
            cuenta: '',
            fechaIngreso: new Date().toISOString().substr(0, 10),
            anchor: null,
            open: false,
            openConfirm: false,
            openDeny: false
        }
        this.handleClose = this.handleClose.bind(this);
        this.handleCloseConfirm = this.handleCloseConfirm.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.selectTipoId = this.selectTipoId.bind(this);
        this.selectRebajos = this.selectRebajos.bind(this);
        this.agregarConstructor = this.agregarConstructor.bind(this);
        this.popHelp = this.popHelp.bind(this);
    }

    componentDidMount() {
        this.setState({
            rebajos: 'C.C.S.S.',
            tipoId: 'CN',

        });
    }

    handleClose() {
        this.setState({
            open: false,
            openDeny: false
        
        });
    }

    handleCloseConfirm() {
        this.setState({openConfirm: false});
        window.open('/', '_self');
    }

    handleChange(e) {
        this.setState({ [e.target.id]: e.target.value });
    }

    handleDateChange(date) {
        this.setState({ fechaIngreso: `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}` });
    }

    selectTipoId(e) {
        this.setState({ tipoId: e.target.value });
    }

    selectRebajos(e) {
        this.setState({ rebajos: e.target.value });
    }

    async agregarConstructor() {
        let requeridos = [
            this.state.id,
            this.state.nombre,
            this.state.puesto,
            this.state.salario
        ]

        for (var i = 0; i < requeridos.length; i++) {
            if (requeridos[i] === '' || requeridos[i] === 0) {
                this.setState({ open: true });
                return;
            }
        }

        let params = {
            tipoId: this.state.tipoId,
            id: this.state.id,
            nacionalidad: this.state.nacionalidad,
            nombre: this.state.nombre,
            tel: this.state.tel,
            puesto: this.state.puesto,
            salario: this.state.salario,
            rebajos: this.state.rebajos,
            cuenta: this.state.cuenta,
            fechaIngreso: this.state.fechaIngreso,
        };
        let response = await servidor.agregarConstructor(this.props.token, params);
        console.log(response)
        if (response.status === 200) {
            this.setState({ openConfirm: true });
            return;
        } else {
            this.setState({ openDeny: true });
            return;
        }
    }

    popHelp(event) {
        this.setState({ anchor: (this.state.anchor ? null : event.currentTarget) });
    }

    render() {
        return (
            <div className="AgregarConstructor">
                <Paper component="div" className="Formulario">
                    <Container style={{ paddingTop: 15 }}>
                        <Typography variant='h4'>Formulario de constructor nuevo</Typography>
                    </Container>
                    <div className="Campo">
                        <Typography variant='h6'>Tipo de identificación</Typography>
                        <div className="HelpSelect">
                            <Select value={this.state.tipoId} onChange={this.selectTipoId}>
                                <MenuItem value={'CN'}>CN</MenuItem>
                                <MenuItem value={'CR'}>CR</MenuItem>
                                <MenuItem value={'DU'}>DU</MenuItem>
                                <MenuItem value={'NP'}>NP</MenuItem>
                                <MenuItem value={'PT'}>PT</MenuItem>
                                <MenuItem value={'SD'}>SD</MenuItem>
                            </Select>
                            <Popover open={Boolean(this.state.anchor)} anchorEl={this.state.anchor} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} transformOrigin={{ vertical: 'top', horizontal: 'left' }} onClose={this.popHelp} disableRestoreFocus>
                                <h3 style={{ marginBottom: 4, paddingLeft: 10 }}>Tipos de identificación:</h3>
                                <ul style={{ marginTop: 4, marginRight: 10 }}>
                                    <li>CN = Cédula Nacional</li>
                                    <li>CR = Cédula de Residencia, Régimen Especial, Refugiado</li>
                                    <li>DU = Documento Único, DIMEX, DIDI</li>
                                    <li>NP = Pasaporte</li>
                                    <li>PT = Permiso de Trabajo</li>
                                    <li>SD = Sin documentos</li>
                                </ul>
                            </Popover>
                            <HelpIcon onClick={this.popHelp} id="Help-Icon" color="primary" style={{ fontSize: 16, cursor: 'pointer' }} />
                        </div>
                    </div>
                    <div className="Campo">
                        <Typography variant='h6'>Número de identificación<span className="Asterisco"> *</span></Typography>
                        <Input id="id" onChange={this.handleChange} type="text" style={{ width: '70%' }}></Input>
                    </div>
                    <div className="Campo">
                        <Typography variant='h6'>Nacionalidad</Typography>
                        <Input id="nacionalidad" onChange={this.handleChange} type="text" style={{ width: '70%' }} required></Input>
                    </div>
                    <div className="Campo">
                        <Typography variant='h6'>Nombre<span className="Asterisco"> *</span></Typography>
                        <Input id="nombre" onChange={this.handleChange} type="text" style={{ width: '70%' }} required></Input>
                    </div>
                    <div className="Campo">
                        <Typography variant='h6'>Teléfono</Typography>
                        <Input id="tel" onChange={this.handleChange} type="text" style={{ width: '70%' }} required></Input>
                    </div>
                    <div className="Campo">
                        <Typography variant='h6'>Puesto<span className="Asterisco"> *</span></Typography>
                        <Input id="puesto" onChange={this.handleChange} type="text" style={{ width: '70%' }} required></Input>
                    </div>
                    <div className="Campo">
                        <Typography variant='h6'>Salario por hora<span className="Asterisco"> *</span></Typography>
                        <Input id="salario" onChange={this.handleChange} type="number" style={{ width: '70%' }} required></Input>
                    </div>
                    <div className="Campo">
                        <Typography variant='h6'>Rebajos</Typography>
                        <Select id="rebajos" value={this.state.rebajos} onChange={this.selectRebajos}>
                            <MenuItem value='C.C.S.S.'>C.C.S.S.</MenuItem>
                            <MenuItem value='C.C.S.S. e I.S.'>C.C.S.S. e I.S.</MenuItem>
                        </Select>
                    </div>
                    <div className="Campo">
                        <Typography variant='h6'>Número de cuenta</Typography>
                        <Input id="cuenta" onChange={this.handleChange} type="text" style={{ width: '70%' }} required></Input>
                    </div>
                    <div className="Campo">
                        <Typography variant='h6'>Fecha de Ingreso</Typography>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                format="dd/MM/yyyy"
                                margin="normal"
                                value={this.state.fechaIngreso}
                                onChange={this.handleDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </Paper>
                <div className="FooterAgregarTrabajador">
                    <Button variant='contained' color='primary' href="/">Regresar</Button>
                    <Button variant='contained' color='primary' onClick={this.agregarConstructor}>Guardar</Button>
                </div>
                <Dialog onClose={this.handleClose} open={this.state.open}>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Faltan campos obligatorios.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog onClose={this.handleCloseConfirm} open={this.state.openConfirm}>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            El trabajador fue agregado exitosamente.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseConfirm} color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog onClose={this.handleClose} open={this.state.openDeny}>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            No se pudo agregar el trabajador. Compruebe su conección a internet.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default AgregarConstructor;